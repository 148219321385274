import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { useAuth } from "@/context/AuthContext"
import {
    Dialog,
    DialogContent,
} from "@/components/ui/dialog"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"

type UserProfile = {
    user_id: string,
    name: string,
    email: string,
    phone_number: string,
    specialty: string,
    years_of_experience: number,
    medical_license_number: string
    clinic_address: string
}

const formSchema = z.object({
    phone_number: z.string()
        .min(1, "Phone number is required")
        .regex(/^\+?[\d\s-]{10,}$/, "Please enter a valid phone number"),
    specialty: z.string()
        .min(1, "Specialty is required"),
    years_of_experience: z.number()
        .min(0, "Years of experience cannot be negative")
        .max(60, "Please enter a valid years of experience"),
    medical_license_number: z.string()
        .min(1, "Medical license number is required")
        .min(5, "Please enter a valid license number"),
    clinic_address: z.string()
        .min(1, "Clinic address is required")
        .min(10, "Please enter a complete clinic address"),
})

type FormValues = z.infer<typeof formSchema>

export function UserProfileModal({ isOpen, onClose }) {
    return (
        <Dialog open={isOpen} >
            <DialogContent className="bg-transparent border-none">
                <UserProfileForm closeModal={onClose} />
            </DialogContent>
        </Dialog>
    )
}

function UserProfileForm({ closeModal }) {
    const { user: authUser } = useAuth()
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            phone_number: "",
            specialty: "",
            years_of_experience: 0,
            medical_license_number: "",
            clinic_address: "",
        },
    })

    function onSubmit(values: FormValues) {

        closeModal()
        const updatedValues = {
            ...values,
            user_id: authUser?.uid,
            name: authUser?.displayName,
            email: authUser?.email,
        }

        const fetchData = async () => {
            try {
                const res = await fetch("https://api.vitalviewai.com/users", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatedValues),
                })
                const data = await res.json()
                console.log(data)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        }
        fetchData()
    }

    return (
        <Card className="w-full max-w-md mx-auto">
            <CardHeader className="space-y-4">
                <div className="flex items-center gap-3">
                    <Avatar className="h-12 w-12">
                        <AvatarImage src={authUser?.photoURL || ""} alt="Profile picture" />
                        <AvatarFallback>{authUser?.displayName?.charAt(0) || "U"}</AvatarFallback>
                    </Avatar>
                    <div className="flex-1">
                        <h2 className="text-xl font-semibold">{authUser?.displayName || "User"}</h2>
                        <p className="text-sm text-muted-foreground">{authUser?.email}</p>
                    </div>
                </div>
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <CardContent className="space-y-4">
                        <FormField
                            control={form.control}
                            name="phone_number"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Phone Number</FormLabel>
                                    <FormControl>
                                        <Input type="tel" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="specialty"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Specialty</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="years_of_experience"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Years of Experience</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="number"
                                            {...field}
                                            onChange={(e) => field.onChange(parseInt(e.target.value) || 0)}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="medical_license_number"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Medical License Number</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="clinic_address"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Clinic Address</FormLabel>
                                    <FormControl>
                                        <textarea
                                            className="border rounded-md p-2 w-full min-h-[100px] border-gray-300"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </CardContent>
                    <CardFooter className="flex justify-end">
                        <div className="flex gap-2">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => form.reset()}
                            >
                                Clear all
                            </Button>
                            <Button type="submit">
                                Save changes
                            </Button>
                        </div>
                    </CardFooter>
                </form>
            </Form>
        </Card>
    )
}
