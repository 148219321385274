import { CheckIcon } from "@radix-ui/react-icons";

export function SaveIndicator({ saved }: { saved: boolean }) {
  return (
    <div
      className={`flex items-center gap-2 text-sm transition-opacity duration-300 ${saved ? "opacity-100" : "opacity-0"}`}
    >
      <CheckIcon className="h-4 w-4 text-green-500" />
      <span className="text-muted-foreground">All changes saved</span>
    </div>
  )
}

