import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"
import { Upload, X, Plus } from "lucide-react"
import axios from "axios"
import { toast } from "sonner"
import { useAuth } from "@/context/AuthContext"
import CreateNewCase from "./CreateNewCase"
import { DialogClose } from "@radix-ui/react-dialog"
import { config } from "@/config"

export default function AddNewCase({ data, setData }) {
  const [isOpen, setIsOpen] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [gender, setGender] = useState("M")
  const caseLabel = `CS00${data.length + 1}`
  const { user } = useAuth()
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files))
    }
  }

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index))
  }

  const createCase = async (e) => {
    e.preventDefault()
    const patient = {
      case_label: caseLabel,
      name: e.target.name.value,
      age: parseInt(e.target.age.value),
      caseDetail: e.target.caseDetail.value,
      docs: [],
      createdDate: new Date().toISOString(),
      gender: gender,
      status: "open",
    }
    const res = await axios.post(`${config.BASE_URL}/cases`, {
      user_id: user?.uid,
      data: { patient },
    })
    const updatedPatient = res.data

    setData([...data, updatedPatient])
    setIsOpen(false)
    toast.success(`Case ${caseLabel} created successfully`, {
      position: "top-right",
      description: "You can now view the case in the case list.",
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="gap-2 px-5 py-6">
          <Plus className="h-5 w-5" />
          Add New Case
        </Button>
      </DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogContent
            forceMount
            className="flex flex-col sm:max-w-[625px] p-0  max-h-[90vh] overflow-y-auto gap-0 bg-transparent border-0"
          >
            <DialogHeader className="bg-zinc-800 p-8 rounded-t-lg shadow-lg text-white">
              <DialogClose className="absolute top-4 right-4">
                <X className="h-4 w-4 text-white hover:text-gray-600" />
              </DialogClose>
              <div className="text-center">
                <DialogTitle className="text-4xl font-extrabold tracking-wide mb-2 ">
                  Create New Case
                </DialogTitle>
                <DialogDescription className="text-zinc-300 text-sm">
                  Fill in the details to create a new case. Click save when
                  you're done.
                </DialogDescription>
              </div>
            </DialogHeader>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              className=""
            >
              <div className="p-10 bg-white">
                <CreateNewCase />
              </div>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  )
}
