import { useState, useRef } from "react"
import { Button } from "@/components/ui/button"
import { Mic } from "lucide-react"

export function VoiceRecorder({ updateFormWithAudioData }) {
  const [isRecording, setIsRecording] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const chunksRef = useRef<Blob[]>([])

  const uploadAudio = async (blob: Blob) => {
    try {
      setIsUploading(true)
      setUploadError(null)

      const formData = new FormData()
      formData.append("audio", blob, "recording.webm")

      const response = await fetch(
        "https://api.vitalviewai.com/process-consultation/",
        {
          method: "POST",

          body: formData,
        }
      )

      if (!response.ok) {
        throw new Error("Upload failed")
      }

      const data = await response.json()
      console.log("Upload response:", data)
      updateFormWithAudioData(data)

      // Clear audio blob after successful upload
      setAudioBlob(null)
    } catch (error) {
      setUploadError("Failed to upload audio")
      console.error("Upload error:", error)
    } finally {
      setIsUploading(false)
    }
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaRecorderRef.current = new MediaRecorder(stream)

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data)
        }
      }

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/webm" })
        setAudioBlob(blob)
        chunksRef.current = []
      }

      mediaRecorderRef.current.start()
      setIsRecording(true)
    } catch (error) {
      console.error("Error accessing microphone:", error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop())
      setIsRecording(false)

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/webm" })
        setAudioBlob(blob)
        chunksRef.current = []
        uploadAudio(blob)
      }
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  return (
    <div className="space-y-4">
      <Button
        variant="outline"
        size="lg"
        className={`w-full transition-all duration-300 ${
          isRecording
            ? "bg-red-50 border-red-500 text-red-500 hover:bg-red-100"
            : ""
        }`}
        onClick={toggleRecording}
        disabled={isUploading}
      >
        <Mic
          className={`mr-2 h-5 w-5 ${
            isRecording ? "animate-pulse text-red-500" : ""
          }`}
        />
        {isRecording
          ? "End Consultation Recording"
          : "Begin Consultation Recording"}
      </Button>

      {isUploading && <p>Uploading audio...</p>}
      {uploadError && <p className="text-red-500">{uploadError}</p>}

      {audioBlob && !isUploading && (
        <audio
          controls
          src={URL.createObjectURL(audioBlob)}
          className="w-full"
        />
      )}
    </div>
  )
}
