import axios from "axios"
import { toast } from "sonner"
import { config } from "./config"

export const uploadToStorage = async (file: File, userId: string) => {
  if (file) {
    const formData = new FormData()
    formData.append("user_id", userId)
    formData.append("file", file)
    const response = await fetch("${config.BASE_URL}/upload", {
      method: "POST",
      body: formData,
    })
    const data = await response.json()
    console.log(`Uploaded file: ${data.file_url}`)
    return data.file_url
  }
}

// export const updateCaseStatus = async (userId, caseId, status) => {
//   const response = await axios.put(`${config.BASE_URL}/cases`, {
//     user_id: userId,
//     case_id: caseId,
//     data: {
//       patient: {
//         status: status
//       }
//     },
//   })

//   const updatedCase = response.data
//   toast.success(`Case updated successfully`, {
//     position: 'top-right',
//     description: 'You can now view the updated case in the case list.',
//   })
//   return updatedCase
// }

export const generateSummary = async (userId, caseId) => {
  const response = await axios.post(`${config.BASE_URL}/summary`, {
    user_id: userId,
    case_id: caseId,
  })
  const data = response.data
  console.log(`Summary generated: ${data}`)
  toast.success(`Summary generated successfully`, {
    position: "top-right",
    description: "You can now view the summary in the case details.",
  })
}
