//@ts-ignore
import { useState, useRef, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { CardFooter } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Progress } from "@/components/ui/progress"
import {
  Mic,
  Upload,
  UserIcon as Male,
  UserIcon as Female,
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshCw,
  ChevronLeft,
  ChevronRight,
} from "lucide-react"
import { toast } from "sonner"
import axios from "axios" // Import axios
import { generateSummary, uploadToStorage } from "@/utils" // Import your upload function
import { useAuth } from "@/context/AuthContext" // Import useAuth
import { useNavigate } from "react-router-dom"
import { config } from "@/config"

export default function CreateNewCase() {
  // State variables
  const [step, setStep] = useState(1)
  const [isRecording, setIsRecording] = useState(false)
  const [audioTranscript, setAudioTranscript] = useState("")
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [patientName, setPatientName] = useState("")
  const [patientAge, setPatientAge] = useState("")
  const [gender, setGender] = useState("male")
  const [caseDescription, setCaseDescription] = useState("")
  const [uploadedDocs, setUploadedDocs] = useState<File[]>([])
  const { user }: any = useAuth() // Get user from AuthContext
  const [caseLabel, setCaseLabel] = useState("Case Label") // You can set this as needed
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  // Refs and other state variables
  const tabsRef = useRef<HTMLDivElement>(null)
  const [showLeftScroll, setShowLeftScroll] = useState(false)
  const [showRightScroll, setShowRightScroll] = useState(false)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioChunksRef = useRef<Blob[]>([])

  // Navigation handlers
  const handleNextStep = () => setStep(step + 1)
  const handlePrevStep = () => setStep(step - 1)

  // Audio recording functions
  const handleRecording = async () => {
    if (isRecording) {
      stopRecording()
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        mediaRecorderRef.current = new MediaRecorder(stream)
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        )
        mediaRecorderRef.current.addEventListener("stop", handleStop)
        mediaRecorderRef.current.start()
        setIsRecording(true)
        toast.info("Recording started. Click 'Stop Recording' to finish.", {
          duration: 5000,
        })
      } catch (error) {
        console.error("Error accessing microphone:", error)
        toast.error(
          "Error accessing microphone. Please check your browser settings."
        )
      }
    }
  }

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data)
    }
  }

  const handleStop = async () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" })
    setAudioBlob(audioBlob)
    const audioUrl = URL.createObjectURL(audioBlob)
    setAudioUrl(audioUrl)
    setIsRecording(false)
    await simulateTranscription(audioBlob)
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
    }
  }

  const convertBlobToFile = (blob: Blob, fileName: string): File | null => {
    if (!blob) {
      console.error("Blob is null or undefined")
      return null
    }
    const file = new File([blob], fileName, { type: blob.type })
    return file
  }

  const simulateTranscription = async (audio: Blob) => {
    if (!audio) {
      toast.error("No audio file available for transcription.")
      return
    }
    const audioFile = convertBlobToFile(audio, "recording.webm")
    if (!audioFile) {
      toast.error("Failed to convert audio blob to file.")
      return
    }

    const data = new FormData()
    data.append("file", audioFile)

    try {
      const response = await fetch(`${config.BASE_URL}/transcribe`, {
        method: "POST",
        body: data,
      })

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const result = await response.json()
      setAudioTranscript(result.text)
      setCaseDescription(result.text)
      toast.success("Audio transcription completed successfully.")
    } catch (error) {
      console.error("Error transcribing audio:", error)
      toast.error("Error transcribing audio.")
    }
  }

  const resetAudio = () => {
    setAudioBlob(null)
    setAudioUrl(null)
    setAudioTranscript("")
    audioChunksRef.current = []
    toast.info("Audio recording reset successfully.")
  }

  // Tabs scrolling functions
  const scrollTabs = (direction: "left" | "right") => {
    if (tabsRef.current) {
      const scrollAmount = 100
      if (direction === "left") {
        tabsRef.current.scrollLeft -= scrollAmount
      } else {
        tabsRef.current.scrollLeft += scrollAmount
      }
    }
  }

  useEffect(() => {
    const checkScroll = () => {
      if (tabsRef.current) {
        setShowLeftScroll(tabsRef.current.scrollLeft > 0)
        setShowRightScroll(
          tabsRef.current.scrollLeft <
            tabsRef.current.scrollWidth - tabsRef.current.clientWidth
        )
      }
    }

    checkScroll()
    window.addEventListener("resize", checkScroll)
    if (tabsRef.current) {
      tabsRef.current.addEventListener("scroll", checkScroll)
    }

    return () => {
      window.removeEventListener("resize", checkScroll)
      if (tabsRef.current) {
        tabsRef.current.removeEventListener("scroll", checkScroll)
      }
    }
  }, [])

  // Handle document uploads
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUploadedDocs(Array.from(e.target.files))
    }
  }

  // Function to create a new case
  const createCase = async () => {
    toast.info("Creating case...", { duration: 5000 })
    setLoading(true)
    // // Upload audio file if it exists
    // let audioFileUrl = ''
    // if (audioBlob) {
    //   const audioFile = convertBlobToFile(audioBlob, "recording.webm")
    //   audioFileUrl = await uploadToStorage(audioFile, user.uid)
    // }

    // Upload documents and collect their URLs
    const docUrls = []
    for (const file of uploadedDocs) {
      const url = await uploadToStorage(file, user.uid)
      //@ts-ignore
      docUrls.push(url)
    }

    // Compile patient data
    const patient = {
      case_label: caseLabel,
      name: patientName,
      age: parseInt(patientAge),
      caseDetail: caseDescription || audioTranscript,
      docs: docUrls,
      // audio: audioFileUrl,
      createdDate: new Date().toISOString(),
      gender: gender,
      status: "Processing",
    }

    try {
      const res = await axios.post(`${config.BASE_URL}/cases`, {
        user_id: user.uid,
        data: { patient },
      })
      console.log("Case created:", res.data)
      if (res.data.status !== "error") {
        try {
          await generateSummary(user.uid, res.data?.case_id)
          toast.success("Case created successfully!")
          navigate(`/cases/${res.data?.case_id}`)
        } catch (error) {
          console.error("Error generating summary:", error)
          toast.error("All systems are at capacity. Please try again.")
        } finally {
          setLoading(false)
        }
      }
      // Reset form or navigate as needed
    } catch (error) {
      console.error("Error creating case:", error)
      toast.error("Error creating case.")
    }
  }

  return (
    <div className="container mx-auto max-w-4xl border-none">
      <div className="shadow-none">
        <div className="py-4">
          <Progress value={(step / 4) * 100} className="mb-6" />
          <Tabs value={`step${step}`} className="w-full">
            <div className="relative mb-6">
              {showLeftScroll && (
                <Button
                  variant="outline"
                  size="icon"
                  className="absolute -left-4 top-1/2 -translate-y-1/2 z-10 bg-white shadow-md"
                  onClick={() => scrollTabs("left")}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                  <span className="sr-only">Scroll left</span>
                </Button>
              )}
              {showRightScroll && (
                <Button
                  variant="outline"
                  size="icon"
                  className="absolute -right-4 top-1/2 -translate-y-1/2 z-10 bg-white shadow-md"
                  onClick={() => scrollTabs("right")}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                  <span className="sr-only">Scroll right</span>
                </Button>
              )}
              <span className="text-sm text-gray-500 mb-2">
                Step {step} of 4
              </span>
              <TabsList
                ref={tabsRef}
                className="flex w-full rounded-lg justify-start overflow-x-auto scrollbar-hide bg-zinc-100"
              >
                <TabsTrigger
                  value="step1"
                  className="flex-shrink-0 text-sm cursor-not-allowed"
                >
                  Basic Info
                </TabsTrigger>
                <TabsTrigger
                  value="step2"
                  className="flex-shrink-0 text-sm cursor-not-allowed"
                >
                  Audio & Symptoms
                </TabsTrigger>
                <TabsTrigger
                  value="step3"
                  className="flex-shrink-0 text-sm cursor-not-allowed"
                >
                  Case Details
                </TabsTrigger>
                <TabsTrigger
                  value="step4"
                  className="flex-shrink-0 text-sm cursor-not-allowed"
                >
                  Review
                </TabsTrigger>
              </TabsList>
            </div>
            {/* Step 1: Basic Info */}
            <TabsContent value="step1" className="min-h-[300px]">
              <div className="space-y-6">
                <div>
                  <Label htmlFor="patientName" className="text-lg font-medium">
                    Name
                  </Label>
                  <Input
                    id="patientName"
                    placeholder="Enter patient name"
                    className="mt-1"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                </div>
                <div>
                  <Label htmlFor="patientAge" className="text-lg font-medium">
                    Age
                  </Label>
                  <Input
                    id="patientAge"
                    type="number"
                    placeholder="Enter patient age"
                    className="mt-1"
                    value={patientAge}
                    onChange={(e) => setPatientAge(e.target.value)}
                  />
                </div>
                <div>
                  <Label className="text-lg font-medium mb-2 block">
                    Gender
                  </Label>
                  <RadioGroup
                    value={gender}
                    onValueChange={(value) => setGender(value)}
                    className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4"
                  >
                    <div className="flex items-center space-x-2 bg-gray-100 p-3 rounded-lg">
                      <RadioGroupItem value="male" id="male" />
                      <Label htmlFor="male" className="flex items-center">
                        <Male className="mr-2 h-5 w-5 text-zinc-500" />
                        Male
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2 bg-gray-100 p-3 rounded-lg">
                      <RadioGroupItem value="female" id="female" />
                      <Label htmlFor="female" className="flex items-center">
                        <Female className="mr-2 h-5 w-5 text-zinc-500" />
                        Female
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </TabsContent>
            {/* Step 2: Audio & Symptoms */}
            <TabsContent value="step2" className="min-h-[300px]">
              <div className="space-y-6">
                <div>
                  <Label className="text-lg font-medium mb-2 block">
                    Audio Recording
                  </Label>
                  <div className="flex space-x-2">
                    <Button
                      onClick={handleRecording}
                      className={`flex-1 h-24 ${
                        isRecording
                          ? "bg-red-500 hover:bg-red-600"
                          : "bg-zinc-700 hover:bg-zinc-800"
                      } text-white transition-colors duration-200`}
                    >
                      <Mic className="mr-2 h-6 w-6" />
                      {isRecording ? "Stop Recording" : "Start Recording"}
                    </Button>
                    <Button
                      onClick={resetAudio}
                      className="h-24 bg-gray-200 hover:bg-gray-300 text-zinc-700"
                      disabled={!audioBlob && !audioTranscript}
                    >
                      <RefreshCw className="mr-2 h-6 w-6" />
                      Reset
                    </Button>
                  </div>
                </div>
                {audioUrl && (
                  <div>
                    <Label className="text-lg font-medium mb-2 block">
                      Recorded Audio
                    </Label>
                    <audio controls src={audioUrl} className="w-full" />
                  </div>
                )}
                {audioTranscript && (
                  <div>
                    <Label className="text-lg font-medium mb-2 block">
                      Transcribed Audio
                    </Label>
                    <Textarea
                      value={audioTranscript}
                      readOnly
                      className="h-24 bg-gray-50"
                    />
                  </div>
                )}
              </div>
            </TabsContent>
            {/* Step 3: Case Details */}
            <TabsContent value="step3" className="min-h-[300px]">
              <div className="space-y-6">
                <div>
                  <Label
                    htmlFor="caseDescription"
                    className="text-lg font-medium mb-2 block"
                  >
                    Case Description
                  </Label>
                  <Textarea
                    id="caseDescription"
                    placeholder="Describe the case details"
                    value={caseDescription}
                    onChange={(e) => setCaseDescription(e.target.value)}
                    className="h-32"
                  />
                </div>
                <div>
                  <Label className="text-lg font-medium mb-2 block">
                    Upload Documents
                  </Label>
                  <label
                    htmlFor="file-upload"
                    className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:bg-gray-50 transition-colors duration-200 cursor-pointer block"
                  >
                    <Upload className="mx-auto mb-4 h-12 w-12 text-gray-400" />
                    <p className="text-sm text-gray-600">
                      Drag and drop files here or click to upload
                    </p>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                </div>
                <div className="mt-4">
                  {uploadedDocs.length > 0 && (
                    <div>
                      <h3 className="text-lg font-medium">Uploaded Files:</h3>
                      <ul className="list-disc list-inside mt-2">
                        {uploadedDocs.map((file, index) => (
                          <li key={index} className="text-sm text-gray-700">
                            {file.name}
                            {file.type.startsWith("image/") && (
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                className="mt-2 h-32 w-32 object-cover rounded-lg"
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </TabsContent>
            {/* Step 4: Review */}
            <TabsContent value="step4" className="min-h-[300px]">
              <div className="space-y-6">
                <h3 className="text-xl font-semibold mb-4">Case Summary</h3>
                <div className="bg-gray-50 p-4 rounded-lg space-y-2">
                  <p>
                    <strong className="font-medium">Patient:</strong>{" "}
                    {patientName || "N/A"}, {patientAge || "N/A"} years old,{" "}
                    {gender.charAt(0).toUpperCase() + gender.slice(1)}
                  </p>
                  <p>
                    <strong className="font-medium">Description:</strong>{" "}
                    {caseDescription || audioTranscript || "N/A"}
                  </p>
                  <p>
                    <strong className="font-medium">Documents:</strong>{" "}
                    {uploadedDocs.length} files uploaded
                  </p>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
        {/* Navigation Buttons */}
        <CardFooter className="flex flex-col space-y-2 sm:flex-row sm:justify-between sm:space-y-0 bg-gray-50 px-6 py-4">
          {step > 1 && (
            <Button
              variant="outline"
              onClick={handlePrevStep}
              className="w-full sm:w-auto"
            >
              <ChevronLeft className="mr-2 h-4 w-4" /> Previous
            </Button>
          )}
          {step < 4 ? (
            <Button
              className="w-full sm:w-auto sm:ml-auto"
              onClick={handleNextStep}
            >
              Next <ChevronRight className="ml-2 h-4 w-4" />
            </Button>
          ) : (
            <Button
              className="w-full sm:w-auto sm:ml-auto"
              onClick={createCase}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <span>Creating...</span>
                  <div className="animate-spin ml-2">
                    <RefreshCw className="h-4 w-4" />
                  </div>
                </div>
              ) : (
                "Create Case"
              )}
            </Button>
          )}
        </CardFooter>
      </div>
    </div>
  )
}
