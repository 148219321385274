import React, { useEffect } from "react"
import {
  TrendingUp,
  Search,
  FileSearch,
  ArrowUpRight,
  Microscope,
  FlaskConical,
} from "lucide-react"
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from "recharts"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { useNavigate } from "react-router-dom"
import { Badge } from "@/components/ui/badge"
import { useAuth } from "@/context/AuthContext"
import { config } from "@/config"

const chartData = [
  { month: "January", desktop: 186, mobile: 80 },
  { month: "February", desktop: 305, mobile: 200 },
  { month: "March", desktop: 237, mobile: 120 },
  { month: "April", desktop: 73, mobile: 190 },
  { month: "May", desktop: 209, mobile: 130 },
  { month: "June", desktop: 214, mobile: 140 },
]

export function DashboardCharts() {
  const [stats, setStats] = React.useState({
    xray_count: 0,
    research_count: 6,
  })
  const navigate = useNavigate()
  const { user } = useAuth()
  useEffect(() => {
    async function fetchStats() {
      await fetch(`${config.BASE_URL}/stats/${user?.uid}`)
        .then((res) => res.json())
        .then((data) => {
          setStats(data)
        })
    }
    fetchStats()
  }, [])

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {/* Main Activity Chart */}
      <Card className="bg-sidebar-accent shadow-sm border-slate-200">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div>
            <CardTitle className="text-sm font-medium text-slate-600">
              Case Activity
            </CardTitle>
            <CardDescription className="text-xs text-slate-500">
              Monthly platform activity trends
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div className="h-[200px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 10,
                  left: -20,
                  bottom: 0,
                }}
              >
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => value.slice(0, 3)}
                  tick={{ fontSize: 12, fill: "#64748b" }}
                />
                <defs>
                  <linearGradient id="colorDesktop" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0f172a" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0f172a" stopOpacity={0.1} />
                  </linearGradient>
                  <linearGradient id="colorMobile" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#64748b" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#64748b" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="desktop"
                  stroke="#0f172a"
                  fill="url(#colorDesktop)"
                  fillOpacity={0.5}
                  strokeWidth={2}
                />
                <Area
                  type="monotone"
                  dataKey="mobile"
                  stroke="#64748b"
                  fill="url(#colorMobile)"
                  fillOpacity={0.5}
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
        <CardFooter>
          <div className="flex w-full items-start gap-2 text-xs">
            <div className="grid gap-1">
              <div className="flex items-center gap-1 font-medium text-slate-600">
                <TrendingUp className="h-3 w-3" />
                5.2% increase this month
              </div>
              <div className="text-slate-500">January - June 2025</div>
            </div>
          </div>
        </CardFooter>
      </Card>

      {/* X-ray Analysis Card */}
      <Card className="bg-sidebar-accent shadow-sm border-slate-200 group">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div>
            <CardTitle className="text-sm font-medium text-slate-600">
              X-ray Analysis
            </CardTitle>
            <CardDescription className="text-xs text-slate-500">
              Total analyzed X-rays
            </CardDescription>
          </div>
          <Badge
            variant={"outline"}
            className="cursor-pointer py-2 bg-zinc-300 hover:bg-primary/90 hover:text-white transition-colors flex items-center gap-2 pl-2"
            onClick={() => navigate("/analyze")}
          >
            <Microscope className="h-3.5 w-3.5" />
            <span>Analyze X-ray</span>
            <ArrowUpRight className="h-4 w-4 ml-1" />
          </Badge>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center h-[200px] space-y-4">
            <div className="text-4xl font-bold text-slate-900">
              {stats.xray_count}
            </div>
            <div className="text-sm text-slate-600">X-rays analyzed</div>
            <div className="flex items-center gap-1 text-sm text-emerald-600">
              <ArrowUpRight className="h-4 w-4" />
              12% increase from last month
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <div className="text-xs text-slate-500">
            Last updated today at 09:00 AM
          </div>
        </CardFooter>
      </Card>

      {/* Research Card */}
      <Card className="bg-sidebar-accent shadow-sm border-slate-200 group">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div>
            <CardTitle className="text-sm font-medium text-slate-600">
              Research
            </CardTitle>
            <CardDescription className="text-xs text-slate-500">
              Total research papers
            </CardDescription>
          </div>
          <Badge
            variant={"outline"}
            className="cursor-pointer py-2 bg-zinc-300 hover:bg-primary/90 hover:text-white transition-colors flex items-center gap-2 pl-2"
            onClick={() => navigate("/research")}
          >
            <FlaskConical className="h-3.5 w-3.5" />
            <span>Research</span>
            <ArrowUpRight className="h-4 w-4 ml-1" />
          </Badge>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center h-[200px] space-y-4">
            <div className="text-4xl font-bold text-slate-900">
              {stats.research_count}
            </div>
            <div className="text-sm text-slate-600">Published papers</div>
            <div className="flex items-center gap-1 text-sm text-emerald-600">
              <ArrowUpRight className="h-4 w-4" />
              8% increase from last month
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <div className="text-xs text-slate-500">
            Last updated today at 09:00 AM
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}

export default DashboardCharts
