import * as React from "react"
import { MessageCircle, X, Maximize2, Minimize2 } from "lucide-react"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { WebSocketProvider } from "@/WebSocketContext"
import ChatPage from "@/pages/ChatPage"
import { useLocation } from "react-router-dom"

type Message = {
  id: string
  role: "user" | "assistant"
  content: string
  timestamp: number
  isStreaming?: boolean
}

const initialMessages: Message[] = [
  {
    id: "1",
    role: "assistant",
    content: "Hello! How can I assist you today?",
    timestamp: Date.now(),
  },
]

export function ChatDrawer() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [inputText, setInputText] = React.useState("")
  const [messages, setMessages] = React.useState<Message[]>(initialMessages)
  const [loading, setLoading] = React.useState(false)
  const hasOpenedBefore = React.useRef(false)

  const isMobile = window.innerWidth < 640

  const location = useLocation()

  React.useEffect(() => {
    if (location.pathname === "/cases" && !hasOpenedBefore.current) {
      const time = setTimeout(() => {
        setIsOpen(true)
        hasOpenedBefore.current = true
      }, 3000)
      return () => clearTimeout(time)
    } else {
      setIsOpen(false)
    }
  }, [location.pathname])

  const systemMessages = [
    {
      role: "system",
      text: `You are Genesis, an advanced medical assistant specialized in providing detailed and accurate differential diagnoses`,
    },
  ]
  const [selectedSystemMessage, setSelectedSystemMessage] = React.useState(
    systemMessages[0]
  )

  return (
    <>
      {!isOpen && (
        <Button
          className="fixed bottom-4 right-4 h-14 w-14 rounded-full shadow-lg hover:bg-primary/90 transition-all duration-300 ease-in-out z-40"
          onClick={() => setIsOpen(true)}
        >
          <MessageCircle className="h-6 w-6" />
          <span className="sr-only">Open medical chat assistant</span>
        </Button>
      )}
      {isOpen && (
        <div className="z-50">
          <div
            className={`fixed ${
              isFullScreen || isMobile
                ? "inset-0"
                : "bottom-4 right-4 w-full max-w-[400px] h-[600px] rounded-lg"
            } bg-background shadow-lg flex flex-col transition-all duration-300 ease-in-out overflow-hidden`}
          >
            <div className="flex justify-between items-center py-2 p-4 bg-primary text-primary-foreground">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <MessageCircle className="h-5 w-5" />
                AI Assist
              </h2>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsFullScreen(!isFullScreen)}
                  className="hover:bg-primary/90"
                >
                  {isFullScreen ? (
                    <Minimize2 className="h-5 w-5" />
                  ) : (
                    <Maximize2 className="h-5 w-5" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsOpen(false)}
                  className="hover:bg-primary/90"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <ScrollArea className="flex-1">
              <WebSocketProvider>
                <ChatPage
                  inputText={inputText}
                  setInputText={setInputText}
                  messages={messages}
                  setMessages={setMessages}
                  setLoading={setLoading}
                  loading={loading}
                />
              </WebSocketProvider>
            </ScrollArea>
          </div>
        </div>
      )}
    </>
  )
}
