import axios from "axios"
import { config } from "../config"

const TEST = false

export const detectAnomalies = async (fileUrl, user_id, case_id) => {
  if (TEST) {
    return {
      inference_id: "0c523d98-6174-4510-af72-ffc80576559c",
      time: 0.03470897100032744,
      image: { width: 800, height: 400 },
      predictions: [
        {
          x: 587.5,
          y: 203.5,
          width: 51.0,
          height: 35.0,
          confidence: 0.21847935020923615,
          class: "Restoration",
          class_id: 6,
          detection_id: "73c75c20-d55f-47c0-9b40-6da1df2d3c65",
        },
        {
          x: 182.0,
          y: 125.5,
          width: 76.0,
          height: 139.0,
          confidence: 0.11164110898971558,
          class: "Restoration",
          class_id: 6,
          detection_id: "e0c7eae5-77df-4127-a064-6bd1fb53953b",
        },
        {
          x: 324.0,
          y: 9.5,
          width: 48.0,
          height: 19.0,
          confidence: 0.07889043539762497,
          class: "Decay",
          class_id: 2,
          detection_id: "55d4b624-cbf5-4471-8c68-db6c646eb84a",
        },
        {
          x: 574.5,
          y: 135.0,
          width: 83.0,
          height: 110.0,
          confidence: 0.05591322481632233,
          class: "Restoration",
          class_id: 6,
          detection_id: "97b9a21e-9a69-40e3-8b15-0986d8a046d1",
        },
        {
          x: 515.0,
          y: 16.5,
          width: 118.0,
          height: 33.0,
          confidence: 0.04595685377717018,
          class: "Decay",
          class_id: 2,
          detection_id: "6dc3a361-5b19-4cdb-8f52-57004eb4f4d3",
        },
        {
          x: 682.0,
          y: 328.0,
          width: 236.0,
          height: 144.0,
          confidence: 0.037759143859148026,
          class: "Missing Tooth",
          class_id: 4,
          detection_id: "4dc4c3fc-9299-4ae7-a2f6-ff18bc91f06e",
        },
        {
          x: 86.0,
          y: 63.0,
          width: 172.0,
          height: 124.0,
          confidence: 0.024745559319853783,
          class: "Decay",
          class_id: 2,
          detection_id: "25a2c5e9-1b07-4165-90d3-760c2377020a",
        },
        {
          x: 591.5,
          y: 261.0,
          width: 61.0,
          height: 150.0,
          confidence: 0.021510861814022064,
          class: "Root Canal Treatment",
          class_id: 7,
          detection_id: "66938187-b5c7-4f0e-a1f0-1b5e20f6f92e",
        },
        {
          x: 714.0,
          y: 56.5,
          width: 166.0,
          height: 113.0,
          confidence: 0.020744694396853447,
          class: "Decay",
          class_id: 2,
          detection_id: "a3302a4c-221b-4812-9606-9ad221df7af3",
        },
        {
          x: 467.5,
          y: 292.5,
          width: 71.0,
          height: 139.0,
          confidence: 0.017227744683623314,
          class: "Root Canal Treatment",
          class_id: 7,
          detection_id: "f5fff787-d9a8-419f-8620-29f8693980e5",
        },
        {
          x: 85.5,
          y: 55.5,
          width: 171.0,
          height: 109.0,
          confidence: 0.011571792885661125,
          class: "Bone Loss",
          class_id: 0,
          detection_id: "dfb196c6-7420-4599-833e-66756d64710c",
        },
      ],
    }
  }
  console.log(`Detecting anomalies in file: ${fileUrl}`)
  const body = {
    image_url: fileUrl,
    user_id: user_id,
    case_id: case_id,
  }
  const response = await axios.post(`${config.BASE_URL}/detect`, body)
  const data = response.data
  console.log(`Anomalies detected: ${data}`)
  return data
}
