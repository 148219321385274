// App.tsx
import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Loading from './components/Loading'
import Login from './components/Login'
import Dashboard from './Dashboard'
import FinishSignUp from './components/FinishSignUp'
import { TooltipProvider } from './components/ui/tooltip'
import './App.css'
import { AuthProvider, useAuth } from './context/AuthContext'
import { UserProfileProvider } from './UserProfileContext'

// Protected route component
const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuth()

  if (loading) {
    return <Loading loading={loading} />
  }

  return user ? <>{children}</> : <Navigate to="/login" />
}

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <TooltipProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/finishSignUp" element={<FinishSignUp />} />

            {/* Protected routes */}

            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <UserProfileProvider>
                    <Dashboard />
                  </UserProfileProvider>
                </PrivateRoute>
              }
            />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </TooltipProvider>
      </Router>
    </AuthProvider>
  )
}

export default App
