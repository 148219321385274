import { useState, useEffect } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"

import { Menu, Stethoscope, Brain } from "lucide-react"
import { VoiceRecorder } from "@/components/voice-recorder"
import { AIInsights } from "@/components/ai-insights"
import { SaveIndicator } from "@/components/save-indicator"

export default function ModernEMR() {
  const [formData, setFormData] = useState({
    patientName: "",
    age: "",
    symptoms: "",
    medicalHistory: "",
    conditions: "",
    allergies: "",
    medications: "",
    surgeries: "",
    familyHistory: "",
    notes: "",
  })
  const [saved, setSaved] = useState(true)

  const updateFormWithAudioData = (data: any) => {
    const formatField = (field: string[] | string | undefined) => {
      if (Array.isArray(field)) return field.join(", ")
      return field || ""
    }

    const appendField = (
      existing: string,
      newData: string[] | string | undefined
    ) => {
      if (!existing) return formatField(newData)
      if (!newData) return existing
      return `${existing}, ${formatField(newData)}`
    }

    setFormData((prevData) => ({
      patientName: data.emr.patientName || prevData.patientName,
      age: data.emr.age?.toString() || prevData.age,
      symptoms: appendField(prevData.symptoms, data.emr.symptoms),
      medicalHistory: appendField(
        prevData.medicalHistory,
        data.emr.medicalHistory
      ),
      conditions: appendField(prevData.conditions, data.emr.condition),
      allergies: appendField(prevData.allergies, data.emr.allergies),
      medications: appendField(prevData.medications, data.emr.medication),
      surgeries: appendField(prevData.surgeries, data.emr.surgeries),
      familyHistory: appendField(
        prevData.familyHistory,
        data.emr.familyHistory
      ),
      notes: appendField(prevData.notes, data.emr.notes),
    }))
    setSaved(false)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    setSaved(false)
  }

  useEffect(() => {
    if (!saved) {
      const timer = setTimeout(() => {
        setSaved(true)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [saved])

  return (
    <div className="min-h-[90vh] bg-gray-50">
      <div className="container mx-auto p-6 h-[calc(100vh-4rem)]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 h-full">
          {/* Left Column - AI Assistance */}
          <div className="space-y-6 h-full flex flex-col">
            <div className="flex items-center gap-2">
              <Brain className="h-6 w-6 text-primary" />
              <h2 className="text-xl font-semibold">AI Clinical Assistant</h2>
            </div>

            {/* Voice Recording */}
            <Card>
              <CardContent className="p-6">
                <VoiceRecorder
                  updateFormWithAudioData={updateFormWithAudioData}
                />
              </CardContent>
            </Card>

            {/* AI Insights */}
            <div className="flex-grow">
              <AIInsights
                symptoms={formData.symptoms}
                conditions={formData.conditions}
              />
            </div>
          </div>

          {/* Right Column - EMR Form */}
          <div className="space-y-6 h-full overflow-y-auto">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">
                Electronic Medical Record
              </h2>
              <SaveIndicator saved={saved} />
            </div>

            <Card>
              <CardContent className="p-6 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="patientName">Patient Name</Label>
                    <Input
                      id="patientName"
                      name="patientName"
                      value={formData.patientName}
                      onChange={handleInputChange}
                      className="transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="Enter patient name"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="age">Age</Label>
                    <Input
                      id="age"
                      name="age"
                      type="number"
                      value={formData.age}
                      onChange={handleInputChange}
                      className="transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      min="0"
                      max="120"
                      placeholder="Enter age"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="symptoms">Symptoms</Label>
                  <Textarea
                    id="symptoms"
                    name="symptoms"
                    value={formData.symptoms}
                    onChange={handleInputChange}
                    className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                    placeholder="Describe current symptoms"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="medicalHistory">Medical History</Label>
                  <Textarea
                    id="medicalHistory"
                    name="medicalHistory"
                    value={formData.medicalHistory}
                    onChange={handleInputChange}
                    className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                    placeholder="Enter medical history"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="conditions">Conditions</Label>
                    <Textarea
                      id="conditions"
                      name="conditions"
                      value={formData.conditions}
                      onChange={handleInputChange}
                      className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="List current conditions"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="allergies">Allergies</Label>
                    <Textarea
                      id="allergies"
                      name="allergies"
                      value={formData.allergies}
                      onChange={handleInputChange}
                      className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="List known allergies"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="medications">Medications</Label>
                    <Textarea
                      id="medications"
                      name="medications"
                      value={formData.medications}
                      onChange={handleInputChange}
                      className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="List current medications"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="surgeries">Surgeries</Label>
                    <Textarea
                      id="surgeries"
                      name="surgeries"
                      value={formData.surgeries}
                      onChange={handleInputChange}
                      className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="List previous surgeries"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="familyHistory">Family History</Label>
                  <Textarea
                    id="familyHistory"
                    name="familyHistory"
                    value={formData.familyHistory}
                    onChange={handleInputChange}
                    className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                    placeholder="Enter relevant family history"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="notes">Notes</Label>
                  <Textarea
                    id="notes"
                    name="notes"
                    value={formData.notes}
                    onChange={handleInputChange}
                    className="min-h-[100px] transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                    placeholder="Additional notes"
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
