import { createContext, useContext, useEffect, useState } from "react"
import { useAuth } from "./context/AuthContext"
import axios from "axios"
import { UserProfileModal } from "./components/UserDetails"
import { config } from "./config"

interface UserProfileContextType {
  openModal: () => void
  closeModal: () => void
}

const UserProfileContext = createContext<UserProfileContextType | undefined>(
  undefined
)

export function UserProfileProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { user } = useAuth()

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    async function fetchData() {
      if (user) {
        try {
          const res = await axios.get(
            `${config.BASE_URL}/check_user/${user?.uid}`
          )
          if (res.data && !res.data.exists) {
            openModal()
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
    fetchData()
  }, [user])

  return (
    <UserProfileContext.Provider value={{ openModal, closeModal }}>
      {children}
      <UserProfileModal isOpen={isModalOpen} onClose={closeModal} />
    </UserProfileContext.Provider>
  )
}

export const useUserProfile = () => {
  const context = useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error("useUserProfile must be used within a UserProfileProvider")
  }
  return context
}
