import { Bird, Rabbit, Turtle } from "lucide-react"
import { Label } from "./ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select"
import { Switch } from "./ui/switch"
import { Slider } from "./ui/slider"
import { Button } from "./ui/button"
import { detectAnomalies } from "../services/inference"

export default function MeasureSettings({
  aiSettings,
  setAISettings,
  processImage,
  loading,
  detect,
}) {
  return (
    <aside className="lg:w-1/5 lg:border-l border-t p-4">
      <h2 className="text-lg font-semibold mb-4">AI Analysis Settings</h2>
      <div className="space-y-6">
        {/* Detection Settings Subcategory */}
        <div>
          <h3 className="text-md font-semibold mb-2">Detection Settings</h3>
          <div className="space-y-4">
            {/* Anomaly Detection */}
            <div className="flex items-center justify-between">
              <Label htmlFor="caries-detection">Anomaly Detection</Label>
              <Switch
                id="caries-detection"
                checked={aiSettings.cariesDetection}
                onCheckedChange={(value) =>
                  setAISettings({ ...aiSettings, cariesDetection: value })
                }
              />
            </div>

            {/* Sensitivity Slider */}
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label htmlFor="sensitivity">Sensitivity</Label>
                <span className="text-sm font-medium">
                  {Math.round(aiSettings.sensitivity * 100)}%
                </span>
              </div>
              <Slider
                id="sensitivity"
                defaultValue={[aiSettings.sensitivity]}
                min={0}
                max={1}
                step={0.01}
                onValueChange={(value) =>
                  setAISettings({ ...aiSettings, sensitivity: value[0] })
                }
              />
            </div>
          </div>
        </div>
        <Button onClick={detect} className="w-full" variant="default" size="sm">
          {loading ? "Detecting Anomalies..." : "Detect Anomalies"}
        </Button>

        {/* Measurement Settings Subcategory */}
        <div>
          <h3 className="text-md font-semibold mb-2">Measurement Settings</h3>
          <div className="space-y-4">
            {/* Enable Measurement */}
            <div className="flex items-center justify-between">
              <Label htmlFor="enable-measurement">Enable Measurement</Label>
              <Switch
                id="enable-measurement"
                checked={aiSettings.enableMeasurement}
                onCheckedChange={(value) =>
                  setAISettings({ ...aiSettings, enableMeasurement: value })
                }
              />
            </div>

            {/* Choose Model */}
            {aiSettings.enableMeasurement && (
              <div className="grid gap-3">
                <Label htmlFor="choose-model">Model</Label>
                <Select
                  //@ts-ignore
                  value={aiSettings.selectedModel || "genesis"}
                  onValueChange={(value) =>
                    //@ts-ignore
                    setAISettings({ ...aiSettings, selectedModel: value })
                  }
                >
                  <SelectTrigger
                    id="choose-model"
                    className="items-start [&_[data-description]]:hidden"
                  >
                    <SelectValue placeholder="Select a model" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="genesis">
                      <div className="flex items-start gap-3 text-muted-foreground">
                        <Rabbit className="w-5 h-5" />
                        <div className="grid gap-0.5">
                          <p>
                            Neural{" "}
                            <span className="font-medium text-foreground">
                              Genesis
                            </span>
                          </p>
                          <p className="text-xs" data-description>
                            Our fastest 7b model for general use cases.
                          </p>
                        </div>
                      </div>
                    </SelectItem>
                    <SelectItem value="explorer">
                      <div className="flex items-start gap-3 text-muted-foreground">
                        <Bird className="w-5 h-5" />
                        <div className="grid gap-0.5">
                          <p>
                            Neural{" "}
                            <span className="font-medium text-foreground">
                              Explorer
                            </span>
                          </p>
                          <p className="text-xs" data-description>
                            Performance and speed for efficiency.
                          </p>
                        </div>
                      </div>
                    </SelectItem>
                    <SelectItem value="quantum">
                      <div className="flex items-start gap-3 text-muted-foreground">
                        <Turtle className="w-5 h-5" />
                        <div className="grid gap-0.5">
                          <p>
                            Neural{" "}
                            <span className="font-medium text-foreground">
                              Quantum
                            </span>
                          </p>
                          <p className="text-xs" data-description>
                            The most powerful 70b model for complex
                            computations.
                          </p>
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>

                {/* Measurement Settings */}
                <div className="grid gap-3">
                  {/* Length Measurement */}
                  <div className="flex items-center justify-between">
                    <Label htmlFor="length-measurement">
                      Length Measurement
                    </Label>
                    <Switch
                      id="length-measurement"
                      checked={aiSettings.lengthMeasurement}
                      onCheckedChange={(value) =>
                        setAISettings({
                          ...aiSettings,
                          lengthMeasurement: value,
                        })
                      }
                    />
                  </div>

                  {/* Curvature Analysis */}
                  <div className="flex items-center justify-between">
                    <Label htmlFor="curvature-analysis">
                      Curvature Analysis
                    </Label>
                    <Switch
                      id="curvature-analysis"
                      checked={aiSettings.curvatureAnalysis}
                      onCheckedChange={(value) =>
                        setAISettings({
                          ...aiSettings,
                          curvatureAnalysis: value,
                        })
                      }
                    />
                  </div>

                  {/* Angle Measurement */}
                  <div className="flex items-center justify-between">
                    <Label htmlFor="angle-measurement">Angle Measurement</Label>
                    <Switch
                      id="angle-measurement"
                      checked={aiSettings.angleMeasurement}
                      onCheckedChange={(value) =>
                        setAISettings({
                          ...aiSettings,
                          angleMeasurement: value,
                        })
                      }
                    />
                  </div>

                  {/* Density Measurement */}
                  <div className="flex items-center justify-between">
                    <Label htmlFor="density-measurement">
                      Density Measurement
                    </Label>
                    <Switch
                      id="density-measurement"
                      checked={aiSettings.densityMeasurement}
                      onCheckedChange={(value) =>
                        setAISettings({
                          ...aiSettings,
                          densityMeasurement: value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button
                    className="w-full"
                    variant="default"
                    size="sm"
                    onClick={() => processImage()}
                  >
                    {loading ? "Processing..." : "Process Image"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </aside>
  )
}
