import React, { useRef, useEffect, useState } from 'react'
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Line,
  Text,
  Group,
  Rect,
  Transformer,
  Label,
  Tag,
} from 'react-konva'
import useImage from 'use-image'
import { Shape } from './Measure'

interface Result {
  segment?: {
    url: string
  }
  url?: string
  measurements: {
    bounding_box: {
      center: [number, number]
      width: number
      height: number
    };
    [key: string]: any
  }
}

interface Prediction {
  class: string
  confidence: number
  sensitivity: number
  bounding_box: {
    center: [number, number]
    width: number
    height: number
  }
}

interface AnomalyResults {
  predictions: Prediction[]
}

interface KonvaCanvasProps {
  file: File
  results: Result[]
  anomalyResults: AnomalyResults
  fileUrl: string
  aiSettings: {
    cariesDetection: boolean
    boneDensityAnalysis: boolean
    rootCanalIdentification: boolean
    sensitivity: number
  }
  tool: 'rectangle' | 'selector' | 'eraser'
  strokeWidth: number
  strokeColor: string
  setTool: React.Dispatch<React.SetStateAction<'rectangle' | 'selector' | 'eraser'>>
  shapes: Shape[]
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>

}

export function KonvaCanvas({
  file,
  results,
  aiSettings,
  tool,
  fileUrl,
  anomalyResults,
  strokeWidth,
  strokeColor,
  setTool,
  shapes,
  setShapes
}: KonvaCanvasProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const stageRef = useRef<any>(null)
  const transformerRef = useRef<any>(null)
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 })
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [image] = useImage(imageURL || '')
  const [resultImages, setResultImages] = useState<(HTMLImageElement | null)[]>([])
  const [hoveredSegment, setHoveredSegment] = useState<number | null>(null)
  const [hoveredAnomaly, setHoveredAnomaly] = useState<string | null>(null)

  const [currentShape, setCurrentShape] = useState<Shape | null>(null)
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [draggingId, setDraggingId] = useState<string | null>(null)
  const [transformingId, setTransformingId] = useState<string | null>(null)
  const [editingLabelId, setEditingLabelId] = useState<string | null>(null)
  const isDrawing = useRef(false)

  // Image dimensions and position
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })

  // Scaling factors for coordinates
  const [scale, setScale] = useState(1)

  // Load the base image
  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setImageURL(e.target?.result as string)
        console.log('Base image loaded:', e.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }, [file])


  useEffect(() => {
    if (fileUrl) {
      setImageURL(fileUrl)
    }
  }, [fileUrl])



  // Load result images
  useEffect(() => {
    const loadImages = async () => {
      const chunkSize = 5
      const loadChunk = async (chunk: Result[], startIndex: number) => {
        return Promise.all(
          chunk.map((result, index) => {
            return new Promise<HTMLImageElement | null>((resolve) => {
              const img = new Image()
              const imgUrl = result?.segment?.url || result?.url
              if (!imgUrl) {
                console.error(`No URL found for result at index ${startIndex + index}`, result)
                resolve(null)
                return
              }
              img.crossOrigin = 'anonymous'
              img.src = imgUrl
              img.onload = () => {
                console.log(`Segment image loaded: ${img.src}`)
                resolve(img)
              }
              img.onerror = (error) => {
                console.error(`Failed to load segment image: ${img.src}`, error)
                resolve(null)
              }
            })
          })
        )
      }

      setResultImages([]) // Reset images before loading new ones
      let loadedImages: (HTMLImageElement | null)[] = []
      for (let i = 0; i < results.length; i += chunkSize) {
        const chunk = results.slice(i, i + chunkSize)
        try {
          const chunkLoadedImages = await loadChunk(chunk, i)
          loadedImages = [...loadedImages, ...chunkLoadedImages]
          setResultImages(loadedImages)
        } catch (error) {
          console.error('Error loading images:', error)
        }
      }
    }

    if (results?.length > 0) {
      loadImages()
    }
  }, [results])

  // Update stage size and scale when window resizes or image loads
  useEffect(() => {
    const updateDimensions = () => {
      if (image && containerRef.current) {
        const container = containerRef.current
        const containerWidth = container.offsetWidth || 800
        const containerHeight = container.offsetHeight || 600

        // Calculate scale to fit image in container
        const scaleX = containerWidth / image.width
        const scaleY = containerHeight / image.height
        const newScale = Math.min(scaleX, scaleY, 1) // Limit scale to 1

        const width = image.width * newScale
        const height = image.height * newScale

        // Center the image
        const x = (containerWidth - width) / 2
        const y = (containerHeight - height) / 2

        setScale(newScale)
        setStageSize({
          width: containerWidth,
          height: containerHeight
        })
        setImageDimensions({
          width,
          height,
          x,
          y
        })
      }
    }

    updateDimensions()

    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [image])

  // Handle shape selection
  useEffect(() => {
    if (selectedId && transformerRef.current && stageRef.current) {
      const node = stageRef.current.findOne(`#${selectedId}`)
      if (node) {
        transformerRef.current.nodes([node])
        transformerRef.current.getLayer().batchDraw()
      }
    }
  }, [selectedId])

  const handleShapeTransform = (shape: Shape, e: any) => {
    const node = e.target
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    node.scaleX(1)
    node.scaleY(1)

    const updatedShape = {
      ...shape,
      x: node.x() / scale,
      y: node.y() / scale,
      width: (node.width() * scaleX) / scale,
      height: (node.height() * scaleY) / scale
    }

    const updatedShapes = shapes.map((s) =>
      s.id === shape.id ? updatedShape : s
    )
    setShapes(updatedShapes)
  }

  const renderShape = (shape: Shape) => {
    // Calculate positions relative to image position
    const shapeX = imageDimensions.x + shape.x * scale
    const shapeY = imageDimensions.y + shape.y * scale
    const shapeWidth = shape.width * scale
    const shapeHeight = shape.height * scale
    const labelX = shapeX
    const labelY = shapeY - 30 * scale

    function handleSelect(id: string): void {
      throw new Error('Function not implemented.')
    }

    return (
      shape.tool === 'rectangle' && (
        <Group key={shape.id}>
          <Rect
            id={shape.id}
            x={shapeX}
            y={shapeY}
            width={shapeWidth}
            height={shapeHeight}
            stroke={shape.strokeColor}
            strokeWidth={shape.strokeWidth}
            draggable={tool === 'selector'}
            onClick={() => handleSelect(shape.id)}
            onTap={() => handleSelect(shape.id)}
            onDragStart={(e) => setDraggingId(shape.id)}
            onDragEnd={(e) => {
              setDraggingId(null)
              const node = e.target
              // Convert coordinates back to image space
              const updatedShape = {
                ...shape,
                x: (node.x() - imageDimensions.x) / scale,
                y: (node.y() - imageDimensions.y) / scale
              }
              setShapes(prevShapes =>
                prevShapes.map(s => s.id === shape.id ? updatedShape : s)
              )
            }}
            onTransformStart={() => setTransformingId(shape.id)}
            onTransformEnd={(e) => {
              setTransformingId(null)
              const node = e.target
              const scaleX = node.scaleX()
              const scaleY = node.scaleY()
              node.scaleX(1)
              node.scaleY(1)
              const updatedShape = {
                ...shape,
                x: (node.x() - imageDimensions.x) / scale,
                y: (node.y() - imageDimensions.y) / scale,
                width: (node.width() * scaleX) / scale,
                height: (node.height() * scaleY) / scale
              }
              setShapes(prevShapes =>
                prevShapes.map(s => s.id === shape.id ? updatedShape : s)
              )
            }}
            perfectDrawEnabled={false}
          />
          {!editingLabelId && draggingId !== shape.id && transformingId !== shape.id && (
            <Label
              x={imageDimensions.x + shape.x * scale}
              y={imageDimensions.y + shape.y * scale - (34 * scale)} // Scale the gap
              onClick={() => setEditingLabelId(shape.id)}
            >
              <Tag
                fill={`red`}
                opacity={0.75}
                cornerRadius={3}
                padding={5 * scale} // Scale the padding
              />
              <Text
                text={shape.label}
                fontSize={20 * scale}
                fill={`white`}
                align="left"
                padding={4 * scale} // Scale the padding
              />
            </Label>
          )}
        </Group>
      )
    )
  }

  const handleMouseDown = (e: any) => {
    if (tool !== 'rectangle' || !stageRef.current) return

    const stage = stageRef.current
    const point = stage.getPointerPosition()
    if (!point) return

    // Convert to image coordinates
    const x = (point.x - imageDimensions.x) / scale
    const y = (point.y - imageDimensions.y) / scale

    isDrawing.current = true
    setCurrentShape({
      tool: 'rectangle',
      x,
      y,
      width: 0,
      height: 0,
      strokeColor,
      strokeWidth,
      id: `rect-${Date.now()}`,
      label: `Label ${shapes.length + 1}`
    })
  }

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current || !currentShape || tool !== 'rectangle' || !stageRef.current) return

    const stage = stageRef.current
    const point = stage.getPointerPosition()
    if (!point) return

    // Convert to image coordinates
    const x = (point.x - imageDimensions.x) / scale
    const y = (point.y - imageDimensions.y) / scale

    const width = x - currentShape.x
    const height = y - currentShape.y

    setCurrentShape({
      ...currentShape,
      width,
      height
    })
  }

  const handlePointerMove = (e: any) => {
    // Handle drawing if currently drawing
    if (isDrawing.current) {
      const stage = e.target.getStage()
      if (!stage) return

      const point = stage.getPointerPosition()
      if (!point) return

      if (tool === 'rectangle' && currentShape) {
        const width = (point.x - (imageDimensions.x + currentShape.x * scale)) / scale
        const height = (point.y - (imageDimensions.y + currentShape.y * scale)) / scale

        const updatedShape = {
          ...currentShape,
          width,
          height,
        }
        setCurrentShape(updatedShape)
      }
      return
    }

    // Handle hover effects for segments
    const pointerPos = e.target.getStage()?.getPointerPosition()
    if (!pointerPos || !results || results.length === 0) {
      setHoveredSegment(null)
      return
    }

    let foundSegment: number | null = null
    for (let i = 0; i < results.length; i++) {
      if (isPointerInsideBoundingBox(results[i].measurements.bounding_box, pointerPos)) {
        foundSegment = i
        break
      }
    }
    setHoveredSegment(foundSegment)
  }

  const getMeasurementPosition = (boundingBox: { center: [number, number]; width: number; height: number }) => {
    const boxX = imageDimensions.x + boundingBox.center[0] * scale
    const boxY = imageDimensions.y + boundingBox.center[1] * scale
    return {
      x: Math.min(boxX + 10, stageSize.width - 150),
      y: Math.min(boxY + 10, stageSize.height - 40),
    }
  }

  const getMeasurementText = (measurements: { [key: string]: any }) => {
    return Object.entries(measurements)
      .filter(([key]) => key !== 'bounding_box')
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n')
  }

  const getColorForClass = (className: string) => {
    const colors: { [key: string]: string } = {
      'Decay': 'red',
      'Restoration': 'green',
      'Missing Tooth': 'yellow',
      'Bone Loss': 'blue',
      'Root Canal Treatment': 'purple',
    }
    return colors[className] || 'white'
  }

  const customCursors = {
    pen: `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white">
        <circle cx="12" cy="12" r="3" fill="none" stroke-width="2"/>
        <path d="M12 2L12 8" stroke-width="2"/>
        <path d="M12 16L12 22" stroke-width="2"/>
        <path d="M2 12L8 12" stroke-width="2"/>
        <path d="M16 12L22 12" stroke-width="2"/>
      </svg>
    `)}`,
    eraser: `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white">
        <rect x="4" y="8" width="16" height="12" rx="2" stroke-width="2"/>
        <path d="M8 8L12 4L16 8" stroke-width="2"/>
      </svg>
    `)}`
  }

  const getCursorStyle = () => {
    switch (tool) {
      case 'rectangle':
        return 'crosshair'
      case 'selector':
        return 'pointer'
      case 'eraser':
        return 'cell'
      default:
        return 'default'
    }
  }

  const startDrawing = (e: any) => {
    const stage = e.target.getStage()
    if (!stage) return

    // Don't start drawing if using selector
    if (tool === 'selector') {
      const clickedOnEmpty = e.target === e.target.getStage()
      if (clickedOnEmpty) {
        setSelectedId(null)
      }
      return
    }

    isDrawing.current = true

    const pos = stage.getPointerPosition()
    if (!pos) return

    if (tool === 'rectangle') {
      const newShape: Shape = {
        tool,
        x: (pos.x - imageDimensions.x) / scale,
        y: (pos.y - imageDimensions.y) / scale,
        width: 0,
        height: 0,
        strokeColor,
        strokeWidth,
        id: `shape-${Date.now()}`,
        label: ''
      }
      setCurrentShape(newShape)
    }
  }

  const draw = (e: any) => {
    if (!isDrawing.current) return

    const stage = e.target.getStage()
    if (!stage) return

    const point = stage.getPointerPosition()
    if (!point) return

    if (tool === 'rectangle' && currentShape) {
      const width = (point.x - (imageDimensions.x + currentShape.x * scale)) / scale
      const height = (point.y - (imageDimensions.y + currentShape.y * scale)) / scale

      const updatedShape = {
        ...currentShape,
        width,
        height,
      }
      setCurrentShape(updatedShape)
    }
  }

  // Bounding box helper function
  const isPointerInsideBoundingBox = (
    boundingBox: { center: [number, number]; width: number; height: number },
    pointerPos: { x: number; y: number }
  ) => {
    const boxX1 = imageDimensions.x + boundingBox.center[0] * scale - (boundingBox.width * scale) / 2
    const boxY1 = imageDimensions.y + boundingBox.center[1] * scale - (boundingBox.height * scale) / 2
    const boxX2 = boxX1 + boundingBox.width * scale
    const boxY2 = boxY1 + boundingBox.height * scale

    return pointerPos.x >= boxX1 && pointerPos.x <= boxX2 && pointerPos.y >= boxY1 && pointerPos.y <= boxY2
  }

  function handleSelect(id: string): void {
    throw new Error('Function not implemented.')
  }

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        minHeight: '600px',
        position: 'relative'
      }}
    >
      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        onMouseDown={startDrawing}
        onTouchStart={startDrawing}
        onMouseMove={handlePointerMove}
        onTouchMove={handlePointerMove}
        onMouseUp={() => {
          isDrawing.current = false
          if (currentShape) {

            if (Math.abs(currentShape.width) < 2 && Math.abs(currentShape.height) < 2) {
              setCurrentShape(null)
              return
            }
            const shapeWithLabel = {
              ...currentShape,
              label: `Untitled ${shapes.length + 1}`,
            }
            setShapes((prevShapes) => [...prevShapes, shapeWithLabel])
            setCurrentShape(null)
            setEditingLabelId(shapeWithLabel.id)

            if (tool === 'rectangle') {
              setTool('selector')
            }
          }
        }}
        onTouchEnd={() => {
          isDrawing.current = false
          if (currentShape) {

            if (Math.abs(currentShape.width) < 2 && Math.abs(currentShape.height) < 2) {
              setCurrentShape(null)
              return
            }
            const shapeWithLabel = {
              ...currentShape,
              label: `Untitled ${shapes.length + 1}`,
            }
            setShapes((prevShapes) => [...prevShapes, shapeWithLabel])
            setCurrentShape(null)
            setEditingLabelId(shapeWithLabel.id)

            if (tool === 'rectangle') {
              setTool('selector')
            }
          }
        }}
        onMouseLeave={() => {
          isDrawing.current = false
          if (currentShape) {

            if (Math.abs(currentShape.width) < 2 && Math.abs(currentShape.height) < 2) {
              setCurrentShape(null)
              return
            }
            const shapeWithLabel = {
              ...currentShape,
              label: `Untitled ${shapes.length + 1}`,
            }
            setShapes((prevShapes) => [...prevShapes, shapeWithLabel])
            setCurrentShape(null)
            setEditingLabelId(shapeWithLabel.id)

            if (tool === 'rectangle') {
              setTool('selector')
            }
          }
        }}
        style={{
          background: '#000',
          cursor: getCursorStyle(),
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Layer>
          {/* Base X-Ray Image */}
          {image && (
            <KonvaImage
              image={image}
              x={imageDimensions.x}
              y={imageDimensions.y}
              width={imageDimensions.width}
              height={imageDimensions.height}
            />
          )}

          {/* Result Segments */}
          {results?.map((result, index) => {
            const segmentImage = resultImages[index]
            if (!segmentImage) return null

            return (
              <Group key={`segment-${index}`}>
                <KonvaImage
                  image={segmentImage}
                  x={imageDimensions.x}
                  y={imageDimensions.y}
                  width={imageDimensions.width}
                  height={imageDimensions.height}
                  opacity={hoveredSegment === index ? 0.8 : 1}
                />
                {hoveredSegment === index && (
                  <Text
                    {...getMeasurementPosition(result.measurements.bounding_box)}
                    text={getMeasurementText(result.measurements)}
                    fontSize={14}
                    fill="yellow"
                    padding={5}
                    background="rgba(0,0,0,0.5)"
                  />
                )}
              </Group>
            )
          })}

          {/* Anomaly Detection Overlay */}
          {anomalyResults?.predictions
            ?.filter((prediction) => prediction.sensitivity >= aiSettings.sensitivity)
            .map((prediction, index) => {
              const { class: className, confidence, bounding_box } = prediction
              const color = getColorForClass(className)
              const boxX = imageDimensions.x + (bounding_box.center[0] * scale) - ((bounding_box.width * scale) / 2)
              const boxY = imageDimensions.y + (bounding_box.center[1] * scale) - ((bounding_box.height * scale) / 2)
              const boxWidth = bounding_box.width * scale
              const boxHeight = bounding_box.height * scale

              return (
                <Group
                  key={className}
                  onMouseEnter={() => setHoveredAnomaly(className)}
                  onMouseLeave={() => setHoveredAnomaly(null)}
                >
                  <Rect
                    x={boxX}
                    y={boxY}
                    width={boxWidth}
                    height={boxHeight}
                    stroke={color}
                    strokeWidth={2}
                    cornerRadius={10}
                    shadowBlur={10}
                    shadowColor={color}
                    shadowOpacity={0.3}
                  />
                  <Label
                    x={boxX}
                    y={boxY - (30 * scale)} // Increased gap from 23 to 35
                  >
                    <Tag
                      fill={`${color}55`}
                      stroke={color}
                      cornerRadius={5}
                      padding={6 * scale} // Increased padding from 5 to 8
                    />
                    <Text
                      text={className}
                      fontSize={14 * scale}
                      fill="white"
                      padding={6 * scale} // Increased padding from 5 to 6
                    />
                  </Label>
                </Group>
              )
            })}
          {/* Drawing Layer */}
          {shapes.map((shape) => (
            shape.tool === 'rectangle' && (
              <Group key={shape.id}>
                <Rect
                  id={shape.id}
                  x={imageDimensions.x + shape.x * scale}
                  y={imageDimensions.y + shape.y * scale}
                  width={shape.width * scale}
                  height={shape.height * scale}
                  stroke={shape.strokeColor}
                  strokeWidth={shape.strokeWidth}
                  draggable={tool === 'selector'}
                  onClick={() => handleSelect(shape.id)}
                  onTap={() => handleSelect(shape.id)}
                  onDragStart={(e) => setDraggingId(shape.id)}
                  onDragEnd={(e) => {
                    setDraggingId(null)
                    const node = e.target
                    // Convert coordinates back to image space
                    const updatedShape = {
                      ...shape,
                      x: (node.x() - imageDimensions.x) / scale,
                      y: (node.y() - imageDimensions.y) / scale
                    }
                    setShapes(prevShapes =>
                      prevShapes.map(s => s.id === shape.id ? updatedShape : s)
                    )
                  }}
                  onTransformStart={() => setTransformingId(shape.id)}
                  onTransformEnd={(e) => {
                    setTransformingId(null)
                    const node = e.target
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()
                    node.scaleX(1)
                    node.scaleY(1)
                    const updatedShape = {
                      ...shape,
                      x: (node.x() - imageDimensions.x) / scale,
                      y: (node.y() - imageDimensions.y) / scale,
                      width: (node.width() * scaleX) / scale,
                      height: (node.height() * scaleY) / scale
                    }
                    setShapes(prevShapes =>
                      prevShapes.map(s => s.id === shape.id ? updatedShape : s)
                    )
                  }}
                  perfectDrawEnabled={false}
                />
                {!editingLabelId && draggingId !== shape.id && transformingId !== shape.id && (
                  <Label
                    x={imageDimensions.x + shape.x * scale}
                    y={imageDimensions.y + shape.y * scale - (34 * scale)} // Scale the gap
                    onClick={() => setEditingLabelId(shape.id)}
                  >
                    <Tag
                      fill={`red`}
                      opacity={0.75}
                      cornerRadius={3}
                      padding={5 * scale} // Scale the padding
                    />
                    <Text
                      text={shape.label}
                      fontSize={20 * scale}
                      fill={`white`}
                      align="left"
                      padding={4 * scale} // Scale the padding
                    />
                  </Label>
                )}
              </Group>
            )
          ))}
          {/* Current Shape */}
          {currentShape && currentShape.tool === 'rectangle' && (
            <Rect
              x={imageDimensions.x + currentShape.x * scale}
              y={imageDimensions.y + currentShape.y * scale}
              width={currentShape.width * scale}
              height={currentShape.height * scale}
              stroke={currentShape.strokeColor}
              strokeWidth={currentShape.strokeWidth}
              draggable={false}
            />
          )}

          {/* Transformer */}
          {tool === 'selector' && (
            <Transformer
              ref={transformerRef}
              keepRatio={false}
              enabledAnchors={[
                'top-left', 'top-center', 'top-right',
                'middle-left', 'middle-right',
                'bottom-left', 'bottom-center', 'bottom-right'
              ]}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // Limit resize
                const minWidth = 5
                const minHeight = 5
                const maxWidth = stageSize.width
                const maxHeight = stageSize.height

                if (
                  newBox.width < minWidth ||
                  newBox.height < minHeight ||
                  newBox.width > maxWidth ||
                  newBox.height > maxHeight
                ) {
                  return oldBox
                }
                return newBox
              }}
            />
          )}
        </Layer>
      </Stage>
      {editingLabelId && shapes.map(shape => {
        if (shape.id === editingLabelId) {
          const stage = stageRef.current?.getStage()
          if (!stage) return null

          const stageBox = stage.container().getBoundingClientRect()
          const x = stageBox.left + imageDimensions.x + shape.x * scale
          const y = stageBox.top + imageDimensions.y + shape.y * scale - 35

          return (
            <input
              key={shape.id}
              style={{
                position: 'fixed',
                left: `${x}px`,
                top: `${y}px`,
                width: '120px',
                height: 'auto',
                zIndex: 1000,
                backgroundColor: 'red',
                color: 'white',
                outline: 'none',
                borderRadius: '4px',
                border: 'none',
                padding: '2px 5px',
              }}
              value={shape.label}
              onChange={(e) => {
                setShapes((prevShapes) =>
                  prevShapes.map((s) =>
                    s.id === shape.id ? { ...s, label: e.target.value } : s
                  )
                )
              }}
              onBlur={() => setEditingLabelId(null)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setEditingLabelId(null)
                }
              }}
              autoFocus
            />
          )
        }
        return null
      })}
    </div>
  )
}