import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { useNavigate } from "react-router-dom"
import { Link2, Search, Wand2, X } from "lucide-react"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Link } from "react-router-dom"
import axios from "axios"
import { useAuth } from "@/context/AuthContext"
import { config } from "@/config"

export default function Research() {
  const [searchQuery, setSearchQuery] = useState("")
  const [audience, setAudience] = useState("medical professionals")
  const [researchType, setResearchType] = useState("research_report")
  const [tone, setTone] = useState("Formal")
  const [pdfLinks, setPdfLinks] = useState<string[]>([])
  const [researchNotes, setResearchNotes] = useState("")
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const { user } = useAuth()

  const suggestedPapers = [
    "https://arxiv.org/abs/2303.12345 Dental Radiograph Analysis",
    "https://arxiv.org/abs/2303.23456 AI in Dental Imaging: A Review",
    "https://arxiv.org/abs/2303.34567 Automated Tooth Segmentation",
  ]
  const navigate = useNavigate()

  const handleStartResearch = async () => {
    console.log("Starting research with the following parameters:")

    // Add the new research to the library with "in-progress" status
    const newResearch = {
      id: Date.now().toString(), // Unique ID for the new research
      title: researchNotes || "Untitled Research",
      date: new Date().toISOString().split("T")[0],
      audience,
      duration: "short", // Default duration
      tone,
      status: "in-progress",
    }

    // Save the new research to local storage or a global state
    const existingProjects = JSON.parse(
      localStorage.getItem("projects") || "[]"
    )
    localStorage.setItem(
      "projects",
      JSON.stringify([...existingProjects, newResearch])
    )

    // Navigate to the research library immediately
    navigate("/research/view-library")

    // Start the research process asynchronously
    startResearchProcess(newResearch.id)
  }

  const startResearchProcess = async (researchId: string) => {
    const requestBody = {
      query: researchNotes,
      sources: pdfLinks,
      tone: tone,
      user_id: user?.uid,
      case_id: "default",
      report_type: researchType,
      audience: audience,
    }

    console.log("Request Body:", requestBody)

    try {
      const res = await axios.post(
        `${config.BASE_URL}/research`, //research
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      console.log("Research completed, dispatching event...")

      // Dispatch a custom event when research is completed
      window.dispatchEvent(
        new CustomEvent("researchCompleted", {
          detail: { researchId, status: "completed", data: res.data },
        })
      )

      const data = res.data
      setResults(data)
      console.log(data)

      // Update the research status to "completed"
      updateResearchStatus(researchId, "completed")
    } catch (error: any) {
      console.error(
        "Error starting research:",
        error.response ? error.response.data : error.message
      )

      // Dispatch a custom event when research fails
      window.dispatchEvent(
        new CustomEvent("researchCompleted", {
          detail: { researchId, status: "failed", error: error.message },
        })
      )

      // Update the research status to "failed"
      updateResearchStatus(researchId, "failed")
    }
  }

  const updateResearchStatus = (id: string, status: "completed" | "failed") => {
    const projects = JSON.parse(localStorage.getItem("projects") || "[]")
    const updatedProjects = projects.map((project: any) =>
      project.id === id ? { ...project, status } : project
    )
    localStorage.setItem("projects", JSON.stringify(updatedProjects))
  }

  const addLink = () => {
    if (
      searchQuery.startsWith("http://") ||
      searchQuery.startsWith("https://")
    ) {
      setPdfLinks((prev) => [...prev, searchQuery])
      setSearchQuery("")
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addLink()
    }
  }

  const removeLink = (index: number) => {
    setPdfLinks((prev) => prev.filter((_, i) => i !== index))
  }

  const startResearch = () => {
    console.log("Starting research with the following parameters:")
    console.log("Audience:", audience)
    console.log("Tone:", tone)
    console.log("PDF Links:", pdfLinks)
    console.log("Research Notes:", researchNotes)

    setLoading(true)
  }

  return (
    <div className="container border rounded-md mt-32 pb-32 mx-auto justify-center items-center p-6 max-w-4xl">
      <header className="flex justify-between items-center mb-6">
        <h1 className="text-3xl text-zinc-800 font-semibold">
          Create a Research
        </h1>
        <Link to="/research/view-library">
          <Button variant="outline">View Library</Button>
        </Link>
      </header>

      <p className="text-sm text-muted-foreground mb-4">
        Create a research project by adding links to papers or topics you want
        to learn more about.
      </p>
      <div className="mb-6">
        <Label htmlFor="researchNotes">Research on this topic</Label>
        <Textarea
          id="researchNotes"
          className="shadow-none bg-white mt-2"
          placeholder="Eg. I am researching about dental radiograph analysis for my upcoming project."
          value={researchNotes}
          onChange={(e) => setResearchNotes(e.target.value)}
          rows={4}
        />
      </div>
      {/* <div className="relative mb-6 flex items-center">
        <Input
          type="text"
          placeholder="Search for a topic or paste a PDF URL"
          className="pl-10 pr-4 py-2 w-full bg-gray-100"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
        <Button onClick={addLink} className="ml-4">Add</Button>
      </div> */}

      {pdfLinks.length > 0 && (
        <div className="mb-6">
          <h2 className="font-semibold mb-2">Your Links</h2>
          <div className="space-y-3">
            {pdfLinks.map((link, index) => (
              <Card key={index} className="shadow-none bg-sidebar-accent">
                <CardContent className="p-4 flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Link2 className="text-muted-foreground" />
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      {link}
                    </a>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeLink(index)}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      )}

      <div className="text-sm text-muted-foreground mb-4">
        VitalView AI is currently available for research on medical domain, with
        media generation optimized for medical topics.
      </div>

      {/* <h2 className="font-semibold mb-4">Example Papers</h2>

      <div className="space-y-3 mb-6">
        {suggestedPapers.map((paper, index) => (
          <Card
            className="hover:shadow-lg cursor-pointer transition-shadow duration-200"
            key={index}
            onClick={() => setPdfLinks((prev) => [...prev, paper.split(' ')[0]])}>
            <CardContent className="p-4 flex items-center space-x-2">
              <Link2 className="text-muted-foreground" />
              <div className="flex flex-col">
                <span className="text-primary hover:underline">{paper.split(' ')[0]}</span>
                <span className="text-sm text-muted-foreground">{paper.split(' ').slice(1).join(' ')}</span>
              </div>
            </CardContent>
          </Card>
        ))}
      </div> */}

      <div className="mb-6 shadow-none">
        <div className="p2-4  space-y-4">
          <div>
            <Label htmlFor="researchType">Research type</Label>
            <Select value={researchType} onValueChange={setResearchType}>
              <SelectTrigger id="researchType">
                <SelectValue placeholder="Select research type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="research_report">Research Report</SelectItem>
                <SelectItem value="resource_report">Resource Report</SelectItem>
                <SelectItem value="outline_report">Outline Report</SelectItem>
                <SelectItem value="detailed_report">Detailed Report</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label htmlFor="audience">Target Audience</Label>
            <Select value={audience} onValueChange={setAudience}>
              <SelectTrigger id="audience">
                <SelectValue placeholder="Select audience" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="medical professionals">
                  Medical Professionals
                </SelectItem>
                <SelectItem value="researchers">Researchers</SelectItem>
                <SelectItem
                  className="text-muted-foreground"
                  disabled
                  value="patients"
                >
                  Patients
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label htmlFor="tone">Tone</Label>
            <Select value={tone} onValueChange={setTone}>
              <SelectTrigger id="tone">
                <SelectValue placeholder="Select tone" />
              </SelectTrigger>
              <SelectContent className="max-w-sm sm:max-w-sm md:max-w-lg lg:max-w-lg xl:max-w-xl">
                <SelectItem value="Objective">
                  Objective (impartial and unbiased presentation of facts and
                  findings)
                </SelectItem>
                <SelectItem value="Formal">
                  Formal (adheres to academic standards with sophisticated
                  language and structure)
                </SelectItem>
                <SelectItem value="Analytical">
                  Analytical (critical evaluation and detailed examination of
                  data and theories)
                </SelectItem>
                <SelectItem value="Persuasive">
                  Persuasive (convincing the audience of a particular viewpoint
                  or argument)
                </SelectItem>
                <SelectItem value="Informative">
                  Informative (providing clear and comprehensive information on
                  a topic)
                </SelectItem>
                <SelectItem value="Explanatory">
                  Explanatory (clarifying complex concepts and processes)
                </SelectItem>
                <SelectItem value="Descriptive">
                  Descriptive (detailed depiction of phenomena, experiments, or
                  case studies)
                </SelectItem>
                <SelectItem value="Critical">
                  Critical (judging the validity and relevance of the research
                  and its conclusions)
                </SelectItem>
                <SelectItem value="Comparative">
                  Comparative (juxtaposing different theories, data, or methods
                  to highlight differences and similarities)
                </SelectItem>
                <SelectItem value="Speculative">
                  Speculative (exploring hypotheses and potential implications
                  or future research directions)
                </SelectItem>
                <SelectItem value="Reflective">
                  Reflective (considering the research process and personal
                  insights or experiences)
                </SelectItem>
                <SelectItem value="Narrative">
                  Narrative (telling a story to illustrate research findings or
                  methodologies)
                </SelectItem>
                <SelectItem value="Humorous">
                  Humorous (light-hearted and engaging, usually to make the
                  content more relatable)
                </SelectItem>
                <SelectItem value="Optimistic">
                  Optimistic (highlighting positive findings and potential
                  benefits)
                </SelectItem>
                <SelectItem value="Pessimistic">
                  Pessimistic (focusing on limitations, challenges, or negative
                  outcomes)
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="flex w-full">
        <Button
          className="flex w-full items-center justify-center space-x-2"
          onClick={handleStartResearch}
        >
          <Wand2 className="w-4 h-4" />
          Start Research
        </Button>
      </div>

      {/* <div className="text-sm text-muted-foreground mt-4 text-center">
        5 research generations remaining for today
      </div> */}
    </div>
  )
}
