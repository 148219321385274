import React, { useEffect, useState } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Button } from "@/components/ui/button"
import { Download, Pencil, Trash, Loader2 } from "lucide-react"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { useAuth } from "@/context/AuthContext"
import axios from "axios"
import { useNavigate } from "react-router-dom"

interface SavedAnnotation {
    annotationId: string
    userId: string
    timestamp: string
    originalImage: string
    shapes: any[]
    aiSettings: any
    segmentation: any[]
    anomalyResults: any
}

export function AnnotatedGallery() {
    const [annotations, setAnnotations] = useState<SavedAnnotation[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const { user } = useAuth()
    const navigate = useNavigate()
    console.log(annotations)
    useEffect(() => {
        const fetchAnnotations = async () => {
            if (!user?.uid) return
            setIsLoading(true)
            try {
                const response = await axios.get(
                    `https://api.vitalviewai.com/annotations/${user.uid}`
                )
                setAnnotations(response.data.annotations)
            } catch (error) {
                console.error("Error fetching annotations:", error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchAnnotations()
    }, [user])

    const handleEdit = (annotation: SavedAnnotation) => {
        // Navigate to Measure component with the annotation data
        navigate("/analyze", { state: { annotation } })
    }

    const handleDelete = async (annotationId: string) => {
        try {
            console.log("Deleting annotation with ID:", annotationId)
            await axios.delete(
                `https://api.vitalviewai.com/annotations/${user?.uid}/${annotationId}`
            )
            setAnnotations((prevAnnotations) =>
                prevAnnotations.filter((annotation) => annotation.annotationId !== annotationId)
            )
        } catch (error) {
            console.error("Error deleting annotation:", error)
        }
    }

    const handleDownload = (imageUrl: string) => {
        const link = document.createElement("a")
        link.href = imageUrl
        link.download = `annotated-image-${Date.now()}.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">Annotated Images Gallery</h1>
            <ScrollArea className="h-[calc(100vh-200px)]">
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center h-[50vh] text-center">
                        <Loader2 className="h-8 w-8 animate-spin text-slate-600 mb-4" />
                        <p className="text-slate-600">Loading annotations...</p>
                    </div>
                ) : annotations.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-[50vh] text-center">
                        <div className="rounded-full bg-slate-100 p-6 mb-4">
                            <img
                                src="/empty-box.png"
                                alt="No images"
                                className="w-16 h-16 opacity-50"
                                onError={(e) => {
                                    e.currentTarget.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="18" x="3" y="3" rx="2"/><circle cx="9" cy="9" r="2"/><path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"/></svg>'
                                }}
                            />
                        </div>
                        <h3 className="text-xl font-semibold text-gray-700 mb-2">
                            No Annotated Images
                        </h3>
                        <p className="text-gray-500 max-w-sm">
                            Start analyzing images to see your annotations appear here
                        </p>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {annotations.map((annotation) => (
                            <Card key={annotation.annotationId} className="overflow-hidden">
                                <CardContent className="p-4">
                                    <div className="relative aspect-video">
                                        <img
                                            src={annotation.originalImage}
                                            alt={`Annotated image ${annotation.annotationId}`}
                                            className="w-full h-full object-cover rounded-md"
                                        />
                                    </div>
                                    <div className="mt-4 flex justify-between items-center">
                                        <div>
                                            <p className="text-xs text-gray-400">
                                                {new Date(annotation.timestamp).toLocaleDateString()}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                {annotation.shapes.length} annotations
                                            </p>
                                        </div>
                                        <div className="flex gap-2">
                                            <Button
                                                variant="outline"
                                                size="icon"
                                                onClick={() => handleDelete(annotation.annotationId)}
                                            >
                                                <Trash className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="outline"
                                                size="icon"
                                                onClick={() => handleEdit(annotation)}
                                            >
                                                <Pencil className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="outline"
                                                size="icon"
                                                onClick={() => handleDownload(annotation.originalImage)}
                                            >
                                                <Download className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                )}
            </ScrollArea>
        </div>
    )
}
