import React, { useEffect, useState } from "react"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Slider } from "@/components/ui/slider"
import { Switch } from "@/components/ui/switch"
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip"
import {
  ZoomInIcon,
  ZoomOutIcon,
  TrashIcon,
  ChevronDownIcon,
  PaintBucketIcon,
  MousePointerIcon,
  EraserIcon,
} from "lucide-react"
import { KonvaCanvas } from "./KonvaCanvas"
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select"
import axios from "axios"
import { UploadFile } from "@/components/UploadFile"
import MeasureSettings from "@/components/MeasureSettings"
import { uploadToStorage } from "@/utils"
import { detectAnomalies } from "@/services/inference"
import { useAuth } from "@/context/AuthContext"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover"
import { useLocation } from "react-router-dom"

export interface Shape {
  tool: "rectangle"
  x: number
  y: number
  width: number
  height: number
  strokeColor: string
  strokeWidth: number
  id: string
  label: string
}

export function Measure() {
  const { user } = useAuth()

  const location = useLocation()

  const annotationData = location.state?.annotation

  console.log(annotationData)

  useEffect(() => {
    if (annotationData) {
      fetch(annotationData.originalImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", { type: "image/png" })
          setFiles([file])
        })
        .catch((error) => console.error("Error creating file:", error))

      setIsSaved(true)
      setFileUrl(annotationData.originalImage || "")
      setShapes(annotationData.shapes || [])
      setSegmentation(annotationData.segmentation || [])
      setAnomalyResults(annotationData.anomalyResults || [])
      setAISettings(
        annotationData.aiSettings || {
          cariesDetection: true,
          boneDensityAnalysis: false,
          rootCanalIdentification: false,
          sensitivity: 0.5,
          lengthMeasurement: true,
          curvatureAnalysis: false,
          angleMeasurement: false,
          densityMeasurement: false,
          enableMeasurement: false,
        }
      )
      setAnnotationId(annotationData.annotationId || null)
    }
  }, [annotationData])

  const [files, setFiles] = useState<File[]>([])
  const [fileUrl, setFileUrl] = useState<string | null>("")
  const [aiSettingsOpen, setAISettingsOpen] = useState<boolean>(false)
  const [tool, setTool] = useState<"rectangle" | "selector" | "eraser">(
    "selector"
  )
  const [anomalyResults, setAnomalyResults] = useState<any>(null)
  const [segmentation, setSegmentation] = useState<any[]>([]) // Initialize as an empty array
  const [loading, setLoading] = useState(false)
  const [strokeWidth, setStrokeWidth] = useState(3)
  const [strokeColor, setStrokeColor] = useState("#000000")
  const [shapes, setShapes] = useState<Shape[]>([])
  const [annotationId, setAnnotationId] = useState<string | null>(null)
  const [isSaved, setIsSaved] = useState(false)
  // AI settings state
  const [aiSettings, setAISettings] = useState({
    cariesDetection: true,
    boneDensityAnalysis: false,
    rootCanalIdentification: false,
    sensitivity: 0.5,
    lengthMeasurement: true,
    curvatureAnalysis: false,
    angleMeasurement: false,
    densityMeasurement: false,
    enableMeasurement: false,
  })

  const processImage = async () => {
    setLoading(true)
    try {
      const body = {
        image_url: fileUrl,
        case_id: "default",
        user_id: user?.uid,
      }
      const res = await axios.post("https://api.vitalviewai.com/segment", body)
      setSegmentation(res.data.segmentations)
    } catch (error) {
      console.error("Error processing image:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files
    if (selectedFiles) {
      setFiles(Array.from(selectedFiles))
      if (user) setFileUrl(await uploadToStorage(selectedFiles[0], user?.uid))
    }
  }

  // Handle reset
  const handleReset = () => {
    setFiles([])
    setShapes([])
  }

  const handleSave = async () => {
    console.log("Saving data...")
    const id = new Date().getTime().toString()
    setAnnotationId(id)
    try {
      const saveData = {
        annotationId: id,
        userId: user?.uid,
        timestamp: new Date().toISOString(),
        originalImage: fileUrl,
        shapes,
        aiSettings,
        segmentation,
        anomalyResults,
      }

      console.log("Saving data:", saveData)

      const res = await axios.post(
        "https://api.vitalviewai.com/annotations",
        saveData
      )

      console.log("Saving data:", res.data)
      setIsSaved(true)
    } catch (error) {
      console.error("Error saving data:", error)
    }
  }

  const handleUpdate = async () => {
    console.log("Updating data...")
    try {
      const updateData = {
        annotationId, // Use existing annotation ID
        userId: user?.uid,
        timestamp: new Date().toISOString(),
        originalImage: fileUrl,
        shapes,
        aiSettings,
        segmentation,
        anomalyResults,
      }

      console.log("Updating data:", updateData)

      const res = await axios.put(
        `https://api.vitalviewai.com/annotations/${user?.uid}/${annotationId}`, // Assuming a PUT endpoint for updates
        updateData
      )

      console.log("Update successful:", res.data)
      // Optionally, you might want to update the isSaved state here or trigger a re-render
    } catch (error) {
      console.error("Error updating data:", error)
    }
  }

  return (
    <div className="flex flex-col h-full">
      {/* Header Toolbar */}
      {files.length > 0 && (
        <header className="flex items-center justify-between p-2 border-b bg-white">
          <div className="flex items-center gap-2">
            {/* Drawing Tools */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={tool === "selector" ? "default" : "ghost"}
                  size="icon"
                  onClick={() => setTool("selector")}
                >
                  <MousePointerIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Select & Move</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={tool === "rectangle" ? "default" : "ghost"}
                  size="icon"
                  onClick={() => setTool("rectangle")}
                >
                  <svg
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <rect x="3" y="3" width="18" height="18" rx="2" />
                  </svg>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Bounding Box</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={tool === "eraser" ? "default" : "ghost"}
                  size="icon"
                  onClick={() => setTool("eraser")}
                >
                  <EraserIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Eraser</TooltipContent>
            </Tooltip>

            {/* Color Picker */}
            <Popover>
              <Tooltip>
                <TooltipTrigger asChild>
                  <PopoverTrigger asChild>
                    <Button variant="ghost" size="icon" className="relative">
                      <PaintBucketIcon className="w-4 h-4" />
                      <div
                        className="absolute w-2 h-2 rounded-full bottom-1 right-1"
                        style={{ backgroundColor: strokeColor }}
                      />
                    </Button>
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>Color Picker</TooltipContent>
              </Tooltip>
              <PopoverContent className="w-64">
                <div className="flex flex-col gap-2">
                  <Label>Stroke Color</Label>
                  <div className="grid grid-cols-6 gap-2">
                    {[
                      "#FF0000",
                      "#00FF00",
                      "#0000FF",
                      "#FFFF00",
                      "#FF00FF",
                      "#00FFFF",
                      "#000000",
                      "#FFFFFF",
                      "#FFA500",
                      "#800080",
                      "#008000",
                      "#FFC0CB",
                    ].map((color) => (
                      <button
                        key={color}
                        className={`w-8 h-8 rounded-full border-2 ${
                          strokeColor === color
                            ? "border-black"
                            : "border-transparent"
                        }`}
                        style={{ backgroundColor: color }}
                        onClick={() => setStrokeColor(color)}
                      />
                    ))}
                  </div>
                  <input
                    type="color"
                    value={strokeColor}
                    onChange={(e) => setStrokeColor(e.target.value)}
                    className="w-full h-8 cursor-pointer"
                  />
                </div>
              </PopoverContent>
            </Popover>

            {/* Zoom Controls */}
            <Button variant="ghost" size="icon">
              <ZoomOutIcon className="w-4 h-4" />
            </Button>
            <Button variant="ghost" size="icon">
              <ZoomInIcon className="w-4 h-4" />
            </Button>

            {/* Reset Button */}
            <Button variant="ghost" size="icon" onClick={handleReset}>
              <TrashIcon className="w-4 h-4" />
            </Button>

            {/* Save Button */}
            {!isSaved ? (
              <Button
                variant="outline"
                size="icon"
                onClick={() => handleSave()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
                  <polyline points="17 21 17 13 7 13 7 21" />
                  <polyline points="7 3 7 8 15 8" />
                </svg>
              </Button>
            ) : (
              <Button variant="outline" size="icon" onClick={handleUpdate}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                >
                  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
                  <polyline points="17 21 17 13 7 13 7 21" />
                  <polyline points="7 3 7 8 15 8" />
                </svg>
              </Button>
            )}
            {/* AI Settings Toggle for Mobile */}
            <div className="lg:hidden">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setAISettingsOpen(true)}
              >
                AI Settings
              </Button>
            </div>
          </div>
        </header>
      )}

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden lg:flex-row flex-col">
        {/* Main Content Area (80%) */}
        <div
          className={`${
            files.length > 0 ? "lg:w-4/5" : "w-full"
          } p-4 overflow-hidden bg-zinc-100 rounded-lg`}
        >
          {files.length > 0 ? (
            <div className="h-full">
              {files.map((file, index) => (
                <div key={index} className="w-full h-full">
                  <KonvaCanvas
                    file={file}
                    //@ts-ignore
                    fileUrl={fileUrl}
                    anomalyResults={anomalyResults}
                    results={segmentation}
                    aiSettings={aiSettings}
                    tool={tool}
                    strokeWidth={strokeWidth}
                    strokeColor={strokeColor}
                    setTool={setTool}
                    shapes={shapes}
                    setShapes={setShapes}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <UploadFile handleFileChange={handleFileChange} />
            </div>
          )}
        </div>

        {/* AI Analysis Settings Sidebar */}
        {files.length > 0 && (
          <MeasureSettings
            aiSettings={aiSettings}
            setAISettings={setAISettings}
            processImage={processImage}
            loading={loading}
            detect={async () => {
              setLoading(true)
              try {
                //@ts-ignore
                const results = await detectAnomalies(
                  fileUrl,
                  user?.uid,
                  "default"
                ) //123 is case
                setAnomalyResults(results)
              } catch (error) {
                console.error("Error detecting anomalies:", error)
              } finally {
                setLoading(false)
              }
            }}
          />
        )}
      </div>

      {/* AI Settings Dialog for Mobile */}
      <Dialog open={aiSettingsOpen} onOpenChange={setAISettingsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>AI Analysis Settings</DialogTitle>
            <DialogDescription>
              Adjust the AI analysis settings for X-ray interpretation.
            </DialogDescription>
          </DialogHeader>
          {/* AI Settings Form */}
          <div className="space-y-6">
            {/* Detection Settings Subcategory */}
            <div>
              <h3 className="text-md font-semibold mb-2">Detection Settings</h3>
              <div className="space-y-4">
                {/* Caries Detection */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="caries-detection-mobile">
                    Caries Detection
                  </Label>
                  <Switch
                    id="caries-detection-mobile"
                    checked={aiSettings.cariesDetection}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, cariesDetection: value })
                    }
                  />
                </div>

                {/* Bone Density Analysis */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="bone-density-mobile">
                    Bone Density Analysis
                  </Label>
                  <Switch
                    id="bone-density-mobile"
                    checked={aiSettings.boneDensityAnalysis}
                    onCheckedChange={(value) =>
                      setAISettings({
                        ...aiSettings,
                        boneDensityAnalysis: value,
                      })
                    }
                  />
                </div>

                {/* Root Canal Identification */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="root-canal-mobile">
                    Root Canal Identification
                  </Label>
                  <Switch
                    id="root-canal-mobile"
                    checked={aiSettings.rootCanalIdentification}
                    onCheckedChange={(value) =>
                      setAISettings({
                        ...aiSettings,
                        rootCanalIdentification: value,
                      })
                    }
                  />
                </div>

                {/* Sensitivity Slider */}
                <div className="space-y-2">
                  <Label htmlFor="sensitivity-mobile">Sensitivity</Label>
                  <Slider
                    id="sensitivity-mobile"
                    defaultValue={[aiSettings.sensitivity]}
                    min={0}
                    max={1}
                    step={0.01}
                    onValueChange={(value) =>
                      setAISettings({ ...aiSettings, sensitivity: value[0] })
                    }
                  />
                </div>
              </div>
            </div>

            {/* Measurement Settings Subcategory */}
            <div>
              <h3 className="text-md font-semibold mb-2">
                Measurement Settings
              </h3>
              <div className="space-y-4">
                {/* Enable Measurement */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="enable-measurement-mobile">
                    Enable Measurement
                  </Label>
                  <Switch
                    id="enable-measurement-mobile"
                    checked={aiSettings.enableMeasurement}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, enableMeasurement: value })
                    }
                  />
                </div>

                {/* Choose Model */}
                {aiSettings.enableMeasurement && (
                  <div className="space-y-2">
                    <Label htmlFor="choose-model-mobile">Choose Model</Label>
                    <Select
                      //@ts-ignore
                      value={aiSettings.selectedModel}
                      onValueChange={(value) =>
                        //@ts-ignore
                        setAISettings({ ...aiSettings, selectedModel: value })
                      }
                    >
                      <SelectTrigger
                        id="choose-model-mobile"
                        className="w-full"
                      >
                        <SelectValue placeholder="Select a model" />
                        <ChevronDownIcon className="w-4 h-4 ml-auto opacity-50" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="genesis">Genesis </SelectItem>
                        <SelectItem value="quantum">Quantum</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
