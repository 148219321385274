import { useState, useEffect } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Brain, HelpCircle } from "lucide-react"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"

interface Insight {
  type: "question" | "finding" | "alert"
  content: string
}

export function AIInsights({ symptoms, conditions }: { symptoms: string; conditions: string }) {
  const [insights, setInsights] = useState<Insight[]>([])

  // Simulate AI analysis
  useEffect(() => {
    if (symptoms || conditions) {
      // In a real app, this would be an API call to an AI endpoint
      const simulatedInsights = generateInsights(symptoms, conditions)
      setInsights(simulatedInsights)
    }
  }, [symptoms, conditions])

  const generateInsights = (symptoms: string, conditions: string): Insight[] => {
    const insights: Insight[] = []

    if (symptoms.toLowerCase().includes("headache")) {
      insights.push(
        { type: "question", content: "How long have you been experiencing headaches?" },
        { type: "question", content: "Is the pain localized or spread across your head?" },
        { type: "finding", content: "Potential tension headache pattern detected" },
      )
    }

    if (symptoms.toLowerCase().includes("fever")) {
      insights.push(
        { type: "question", content: "When did the fever start?" },
        { type: "alert", content: "Consider COVID-19 screening" },
      )
    }

    if (conditions.toLowerCase().includes("diabetes")) {
      insights.push(
        { type: "question", content: "Have you been monitoring your blood sugar levels?" },
        { type: "finding", content: "Regular A1C testing may be needed" },
      )
    }

    // Add default insights if none generated
    if (insights.length === 0 && (symptoms || conditions)) {
      insights.push(
        { type: "question", content: "Can you describe when these symptoms began?" },
        { type: "question", content: "Have you experienced similar symptoms before?" },
      )
    }

    return insights
  }

  const getInsightIcon = (type: Insight["type"]) => {
    switch (type) {
      case "question":
        return <HelpCircle className="h-4 w-4" />
      case "finding":
        return <Brain className="h-4 w-4" />
      case "alert":
        return <ExclamationTriangleIcon className="h-4 w-4" />
    }
  }

  const getInsightColor = (type: Insight["type"]) => {
    switch (type) {
      case "question":
        return "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300"
      case "finding":
        return "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300"
      case "alert":
        return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
    }
  }

  return (
    <Card className="h-full flex flex-col">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Brain className="h-5 w-5" />
          AI Insights
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 flex-grow overflow-y-auto">
        {insights.length > 0 ? (
          insights.map((insight, index) => (
            <div key={index} className="flex items-start gap-3 p-3 rounded-lg bg-gray-50 dark:bg-gray-900">
              <div className={`mt-0.5 rounded-full p-1 ${getInsightColor(insight.type)}`}>
                {getInsightIcon(insight.type)}
              </div>
              <div>
                <Badge variant="outline" className="mb-1">
                  {insight.type.charAt(0).toUpperCase() + insight.type.slice(1)}
                </Badge>
                <p className="text-sm text-gray-600 dark:text-gray-300">{insight.content}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-sm text-gray-500 dark:text-gray-400">
            Enter patient symptoms and conditions to receive AI insights
          </div>
        )}
      </CardContent>
    </Card>
  )
}

