import React, { useState } from "react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Loader2Icon, SearchIcon } from "lucide-react"

interface ScanData {
  id: string
  patientName: string
  date: string
  fileUrl: string
  report?: string
  analysisResult?: string
  previewTemplate?: string
}

export default function CTscan() {
  const [scans, setScans] = useState<ScanData[]>([])
  const [selectedScan, setSelectedScan] = useState<ScanData | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    setIsLoading(true)
    setError(null)

    try {
      const newScan: ScanData = {
        id: Date.now().toString(),
        patientName: "Test Patient",
        date: new Date().toISOString(),
        fileUrl: URL.createObjectURL(file),
      }

      setScans((prev) => [...prev, newScan])
      setSelectedScan(newScan)
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to upload file")
    } finally {
      setIsLoading(false)
    }
  }

  const handleAnalyze = async () => {
    if (!selectedScan) return

    setIsLoading(true)
    try {
      // Simulated backend call for analysis
      const previewTemplate = "Generated preview template from backend"
      const analysisResult = "Detailed analysis from backend"
      const report = "Comprehensive medical report from backend"

      setSelectedScan((prev) =>
        prev
          ? {
              ...prev,
              previewTemplate,
              report,
              analysisResult,
            }
          : null
      )
    } catch (err) {
      setError(err instanceof Error ? err.message : "Analysis failed")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="p-4 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>CT Scan and MRI Scan</CardTitle>
        </CardHeader>
        <CardContent>
          {/* Analysis Section - Moved to Top */}
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2">Analyze Scan</h2>
            <div className="flex items-center space-x-4">
              <Input
                type="file"
                accept=".dcm"
                onChange={handleFileUpload}
                disabled={isLoading}
              />
              {selectedScan && (
                <Button
                  onClick={handleAnalyze}
                  disabled={isLoading}
                  className="w-full"
                >
                  {isLoading ? (
                    <Loader2Icon className="mr-2 animate-spin" />
                  ) : (
                    <SearchIcon className="mr-2" />
                  )}
                  Analyze Scan
                </Button>
              )}
              {isLoading && <Loader2Icon className="animate-spin" />}
            </div>
            {error && (
              <Alert variant="destructive" className="mt-2">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </div>

          {/* Viewer and Reports Section */}
          <div className="grid md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>DICOM Viewer</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="w-full h-96 bg-black flex items-center justify-center text-white">
                  {selectedScan?.previewTemplate ||
                    "Please select or upload a scan"}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Scan Report</CardTitle>
              </CardHeader>
              <CardContent>
                {selectedScan?.report || "No report available"}
              </CardContent>
            </Card>
          </div>

          {/* Previous Scans Section - Generated after scan */}
          {scans.length > 0 && (
            <div className="mt-6">
              <h2 className="text-lg font-semibold mb-2">Previous Scans</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {scans.map((scan) => (
                  <Card
                    key={scan.id}
                    className="cursor-pointer hover:bg-accent"
                    onClick={() => setSelectedScan(scan)}
                  >
                    <CardContent className="p-4">
                      <div className="h-32 bg-gray-200 mb-2"></div>
                      <p className="font-bold">{scan.patientName}</p>
                      <p className="text-sm text-muted-foreground">
                        {scan.date}
                      </p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
