import { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { X, FileText, Bot, RotateCcw } from "lucide-react"
import { Button } from "@/components/ui/button"
import ReactMarkdown from "react-markdown"

interface FileWithProgress {
  file: File
  progress: number
}

export default function FileAnalyzer() {
  const [files, setFiles] = useState<FileWithProgress[]>([])
  const [showUploader, setShowUploader] = useState(true)
  const [showAnalysis, setShowAnalysis] = useState(false)
  const [analysisReport, setAnalysisReport] = useState("")
  const [showAIAnalysis, setShowAIAnalysis] = useState(false)
  const [aiAnalysisReport, setAIAnalysisReport] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      progress: 0,
    }))
    setFiles((prev) => [...prev, ...newFiles])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 50 * 1024 * 1024, // 50MB
  })

  const removeFile = (fileToRemove: FileWithProgress) => {
    setFiles(files.filter((f) => f !== fileToRemove))
  }

  const analyzeReport = async () => {
    const formData = new FormData()
    files.forEach((fileWithProgress) =>
      formData.append("file", fileWithProgress.file)
    )

    try {
      // Make the actual API call
      const response = await fetch("https://api.vitalviewai.com/emr_compare", {
        method: "POST",
        headers: {
          accept: "application/json", // Specify the accepted response format
        },
        body: formData, // Send the FormData containing the files
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`)
      }

      // Parse the JSON response from the API
      const data = await response.json()

      // Set the API response as the analysis report
      setAnalysisReport(`
  # API Analysis Report
  
  ## Response Data
  \`\`\`json
  ${JSON.stringify(data, null, 2)}
  \`\`\`
  
  ## Summary
  The uploaded files have been processed successfully by the API.
      `)

      // Update the view to display the analysis report
      setShowAnalysis(true)
      setShowUploader(false)
    } catch (error: any) {
      // Handle errors and display in the report
      setAnalysisReport(`
  # Error
  
  Failed to analyze the uploaded files. Please try again later.
  
  ## Error Details
  ${error.message}
      `)
      setShowAnalysis(true)
      setShowUploader(false)
    }
  }

  const aiAnalyze = async () => {
    setIsProcessing(true)
    setShowAIAnalysis(true)

    // Simulating AI processing delay
    await new Promise((resolve) => setTimeout(resolve, 2000))

    // Simulating AI-generated analysis
    setAIAnalysisReport(`
# AI-Powered Analysis Report

## Overview
This AI-generated report provides insights based on the ${files.length
      } uploaded file(s).

## Key Findings
1. File types: ${Array.from(new Set(files.map((f) => f.file.type))).join(", ")}
2. Average file size: ${(
        files.reduce((acc, f) => acc + f.file.size, 0) /
        files.length /
        1024 /
        1024
      ).toFixed(2)} MB

## AI Insights
- Based on the file types, this dataset appears to be [file type-specific insights].
- The total data size of ${(
        files.reduce((acc, f) => acc + f.file.size, 0) /
        1024 /
        1024
      ).toFixed(2)} MB suggests [size-related insights].

## Recommendations
1. Consider [AI-generated recommendation 1]
2. Explore [AI-generated recommendation 2]
3. Investigate [AI-generated recommendation 3]

## Next Steps
- Review the AI-generated insights and recommendations
- Conduct further analysis on specific areas of interest
- Implement suggested improvements based on the AI analysis
    `)

    setIsProcessing(false)
  }

  const resetAnalysis = () => {
    setShowUploader(true)
    setShowAnalysis(false)
    setShowAIAnalysis(false)
    setAnalysisReport("")
    setAIAnalysisReport("")
    setFiles([])
  }

  const markdownComponents = {
    h1: ({ node, ...props }: any) => (
      <h1 className="text-3xl font-bold mt-6 mb-4 border-b pb-2" {...props} />
    ),
    h2: ({ node, ...props }: any) => (
      <h2
        className="text-2xl font-semibold mt-6 mb-3 text-primary"
        {...props}
      />
    ),
    h3: ({ node, ...props }: any) => (
      <h3 className="text-xl font-medium mt-4 mb-2" {...props} />
    ),
    p: ({ node, ...props }: any) => (
      <p className="text-gray-700 mb-4 leading-relaxed" {...props} />
    ),
    ul: ({ node, ...props }: any) => (
      <ul className="list-disc pl-6 mb-4 space-y-2" {...props} />
    ),
    ol: ({ node, ...props }: any) => (
      <ol className="list-decimal pl-6 mb-4 space-y-2" {...props} />
    ),
    li: ({ node, ...props }: any) => <li className="mb-1" {...props} />,
    code: ({ node, ...props }: any) => (
      <code
        className="bg-muted px-2 py-1 rounded font-mono text-sm"
        {...props}
      />
    ),
    pre: ({ node, ...props }: any) => (
      <pre
        className="bg-muted p-4 rounded-lg mb-4 overflow-x-auto"
        {...props}
      />
    ),
    blockquote: ({ node, ...props }: any) => (
      <blockquote
        className="border-l-4 border-primary pl-4 my-4 text-gray-600 italic"
        {...props}
      />
    ),
  }

  return (
    <div className="min-h-[90vh] w-full bg-gray-50 flex flex-col">
      {showUploader ? (
        <div className="flex-1 flex items-center justify-center p-6">
          <div className="w-full max-w-md bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold">Upload files</h2>
              <span className="text-2xl">✧</span>
            </div>

            <div
              {...getRootProps()}
              className={`border-2 border-dashed rounded-lg p-8 mb-4 text-center cursor-pointer transition-colors
              ${isDragActive
                  ? "border-primary bg-primary/5"
                  : "border-muted-foreground/25"
                }`}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center gap-2">
                <div className="p-3 bg-muted rounded-full">
                  <svg
                    className="w-6 h-6 text-muted-foreground"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm font-medium">
                    Click to upload or drag and drop
                  </p>
                  <p className="text-xs text-muted-foreground">
                    Maximum file size 50 MB.
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-3 mb-4">
              {files.map((fileWithProgress, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 p-3 bg-muted/50 rounded-lg"
                >
                  <div className="p-2 bg-background rounded">
                    <FileText className="w-4 h-4" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex justify-between items-center mb-1">
                      <p className="text-sm font-medium truncate">
                        {fileWithProgress.file.name}
                      </p>
                      <button
                        onClick={() => removeFile(fileWithProgress)}
                        className="text-muted-foreground hover:text-foreground"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Button
              className="w-full"
              disabled={files.length === 0}
              onClick={analyzeReport}
            >
              Analyze Report
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-[90vh]">
          <div className="bg-white shadow-sm p-4">
            <div className="container mx-auto flex justify-between items-center">
              <h1 className="text-2xl font-bold">File Analysis</h1>
              <div className="space-x-4">
                <Button variant="outline" onClick={resetAnalysis}>
                  <RotateCcw className="mr-2 h-4 w-4" /> Analyze Again
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="h-full flex">
              <div className="w-1/2 p-6 overflow-auto bg-white">
                <div className="max-w-3xl mx-auto">
                  <h2 className="text-2xl font-semibold mb-6 text-primary">
                    Analysis Report
                  </h2>
                  <ReactMarkdown components={markdownComponents}>
                    {analysisReport}
                  </ReactMarkdown>
                </div>
              </div>

              <div className="w-1/2 p-6 overflow-auto border-l border-gray-200 bg-gradient-to-b from-gray-50/50 to-white">
                <div className="max-w-3xl mx-auto">
                  <h2 className="text-2xl font-semibold mb-6 text-emerald-600 flex items-center gap-2">
                    <Bot className="h-6 w-6" /> AI Insights
                  </h2>
                  {showAIAnalysis ? (
                    <ReactMarkdown components={markdownComponents}>
                      {aiAnalysisReport}
                    </ReactMarkdown>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <Button
                        className="w-full max-w-xs bg-gradient-to-r from-emerald-600 to-teal-600 hover:from-emerald-700 hover:to-teal-700 text-white"
                        disabled={isProcessing}
                        onClick={aiAnalyze}
                      >
                        {isProcessing ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Processing...
                          </>
                        ) : (
                          <>
                            <Bot className="mr-2 h-4 w-4" /> Generate AI
                            Analysis
                          </>
                        )}
                      </Button>
                      <p className="mt-4 text-sm text-gray-500 text-center max-w-xs">
                        Get advanced insights using our AI-powered analysis
                        engine
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
